import { Component, OnInit } from '@angular/core';
import { PortadaService } from 'src/app/services/portada.service';
import { GLOBAL } from 'src/app/services/GLOBAL';
import { ContactoService } from 'src/app/services/contacto.service';

declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  public url;
  public general;
  public contacto = {
    nombres:'',
    correo: '',
    telefono: '',
    tema:'',
    mensaje:''
  };
  public msm_success=false;
  public msm_error=false;
  public btn_send=false;

  constructor(
    private _congeneralService : PortadaService,
    private _contactoService : ContactoService
  ) {

    this.url = GLOBAL.url;
  }

  ngOnInit(): void {

    this._congeneralService.get_data().subscribe(
      response =>{
        this.general = response.data;
      },
      error=>{

      }
    );
  }


  onSubmit(contactoForm){

    this.btn_send = true;


    if(!contactoForm.valid){
      this.msm_error = true;
      this.msm_success = false;
    }else{
      let data = {
        nombres:contactoForm.value.nombres,
        correo: contactoForm.value.correo,
        telefono: contactoForm.value.telefono,
        tema:contactoForm.value.tema,
        mensaje:contactoForm.value.mensaje
      }


      this._contactoService.registro(data).subscribe(
        response =>{

          this.contacto = {
            nombres:'',
            correo: '',
            telefono: '',
            tema:'',
            mensaje:''
          }
          this.msm_success = true;
          this.msm_error = false;
          this.btn_send = false;
        },
        error=>{

          this.msm_success = false;
          this.msm_error = true;
          this.btn_send = false;
        }
      );
    }
  }

}
