<app-aside></app-aside>


<div class="page-title-overlap pt-4" style="background-color: #262626;">
  <app-jumbo></app-jumbo>
</div>

<div class="container mb-5 pb-3">
  <div class="bg-light box-shadow-lg rounded-lg overflow-hidden">
    <div class="row">
      <!-- Sidebar-->
      <aside class="col-lg-4">
        <app-menu></app-menu>
      </aside>
      <!-- Content-->
      <section class="col-lg-8 pt-lg-4 mb-3">
        <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
          <div class="row">


            <div class="col-lg-12">
              <div class="border-bottom mt-lg-2 pb-2">
                <h2 class="mt-lg-4">Preguntas Frecuntes</h2>
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                  <p class="text-muted">Cree y elimine las preguntas frecuentes que se mostrarán en su página.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <form #preguntaForm="ngForm" (ngSubmit)="onSubmit(preguntaForm)">
                <div class="card border-0 box-shadow">
                  <div class="card-body">
                    <div class="tab-content" style="margin-top: 20px;">
                      <div class="tab-pane fade active show">
                        <div class="form-group row align-items-center">
                          <div class="col-md-12">
                            <input class="form-control" type="text" placeholder="Pregunta" name="pregunta"
                                   maxlength="50" [(ngModel)]="pregunta.pregunta">
                          </div>
                        </div>
                        <div class="form-group row align-items-center">
                          <div class="col-md-12">
                            <textarea class="form-control" id="textarea-input" rows="5" placeholder="Respuesta"
                                      name="respuesta" [(ngModel)]="pregunta.respuesta"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-primary mb-2 mr-1" type="submit">Añadir</button>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-12 pb-2 mt-4"
                 *ngFor="let item of preguntas">
              <div class="card">
                <div class="card-header">
                  <h3 class="product-title font-size-base" style="margin-bottom: 0px;">
                    <a>{{item.pregunta}}</a></h3>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-9">
                      <div class="media-body pt-2">
                        <div class="font-size-lg text-accent pt-2"><span
                          class="text-muted mr-2">{{item.respuesta}}</span></div>
                      </div>
                    </div>

                    <div class="col-lg-3">
                      <div class="btn-toolbar" role="toolbar" aria-label="Settings toolbar">
                        <div class="btn-group mr-2 mb-2" role="group" aria-label="Settings group">
                          <a data-toggle="modal" [attr.data-target]="'#modal-'+item._id"
                             class="btn btn-dark btn-icon">
                            <i class="czi-trash"></i> &nbsp; Eliminar
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal fade" [attr.id]="'modal-'+item._id" tabindex="-1" role="dialog"
                       style="display: none;" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="modal-title">Confirmación</h4>
                          <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                            aria-hidden="true">×</span></button>
                        </div>
                        <div class="modal-body">
                          <p class="font-size-sm">¿Desea eliminar definitivamente la pregunta?</p>
                        </div>
                        <div class="modal-footer">
                          <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Cancelar</button>
                          <button class="btn btn-primary btn-shadow btn-sm" type="button" (click)="eliminar(item._id)">
                            Suprimir
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>


