<app-aside></app-aside>


<div class="page-title-overlap bg-accent pt-4">
  <app-jumbo></app-jumbo>
</div>

<div class="container mb-5 pb-3">
  <div class="bg-light box-shadow-lg rounded-lg overflow-hidden">
    <div class="row">
      <!-- Sidebar-->
      <aside class="col-lg-4">
        <app-menu></app-menu>
      </aside>
      <!-- Content-->
      <section class="col-lg-8 pt-lg-4 mb-3">
        <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
          <div class="row">

   
      
          
      
              <div class="col-lg-12">
                  <div class="border-bottom mt-lg-2 pb-2">
                      <h1 class="mt-lg-4">Tickets</h1>
                      <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                        <p class="text-muted">Todos los tickets aperturados.</p>
                       
                      </div>
                      
                  </div>
      
                  <div class="table-responsive">
                      <table class="table table-striped table-sm">
                        <thead class="thead-dark">
                          <tr>
                            <th>Usuario</th>
                            <th>Estado</th>
                            <th>Status</th>
                            <th>Chat</th>
                          </tr>
                        </thead>
                       <tbody *ngFor="let item of tickets.tickets| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                           <tr>
                               <td>{{item.user.first_name}} {{item.user.last_name}}</td>
                               <td>
                                <span class="badge badge-success" *ngIf="item.estado == 1">Abierto</span>
                                <span class="badge badge-danger" *ngIf="item.estado == 0">Cerrado</span>
                              </td>
                              <td>
                                <ng-container *ngIf="item.estado == 1">
                                 <span class="badge badge-accent" *ngIf="item.status == '1'"> <span class="czi-message"></span> &nbsp; Espera de respuesta</span>
                                 <span class="badge badge-danger" *ngIf="item.status == '0'"> <span class="czi-message"></span> &nbsp; Nuevo mensaje</span>
                                </ng-container>
                                <ng-container *ngIf="item.estado == 0">
                                 <span class="badge badge-danger" *ngIf="item.estado == 0">Cerrado</span>
                                </ng-container>
                              </td>
                              <td class="text-center"><a [routerLink]="['/admin/tikets/modulo/chat/',item._id]" routerLinkActive="router-link-active" ><span class="czi-message"></span></a></td>
                           </tr>
                       </tbody>
                      </table>
                      <ngb-pagination
                      [(page)]="page"
                      [pageSize]="pageSize"
                      [collectionSize]="count_cat"></ngb-pagination>
                  </div>
              </div>
          </div>
         
        </div>
      </section>
    </div>
  </div>
</div>

<app-foot></app-foot>