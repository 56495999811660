<app-nav></app-nav>

<section class="graycolor">
  <div class="container-fluid mb-4">
    <div class="row">

<div class="col-1 none-desktop"></div>
      <div class="col-10 col-md-12 col-lg-7 col-xl-7 mb-3">

        <div class="pr-lg-0 pt-lg-4">
          <div class="cz-product-gallery">
            <div class="cz-preview order-sm-2">

              <div class="cz-preview-item active">

                <img class="cz-image-zoom" id="active_img_thumb" [src]="url+'galeria/resources/thumbnails/'+first_img"
                     [attr.data-zoom]="url+'galeria/resources/thumbnails/'+first_img" alt="Product image">
                <div class="cz-image-zoom-pane"></div>
              </div>


            </div>
            <div class="cz-thumblist order-sm-1">
              <ng-container *ngFor="let imagen of galeria;let primero = first;let indice=index">
                <a class="cz-thumblist-item active" [attr.id]="'img_'+indice"
                   (click)="click_img(imagen.imagen,'img_'+indice)" *ngIf="primero">
                  <img class="thumb-show" [src]="url+'galeria/resources/thumbnails/'+imagen.imagen"
                       alt="Product thumb">
                </a>
                <a class="cz-thumblist-item" [attr.id]="'img_'+indice" (click)="click_img(imagen.imagen,'img_'+indice)"
                   *ngIf="!primero">
                  <img class="thumb-show" [src]="url+'galeria/resources/thumbnails/'+imagen.imagen"
                       alt="Product thumb">
                </a>
              </ng-container>


              <div class="modal fade" id="video_modal" tabindex="-1" role="dialog" style="display: none;"
                   aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 750px;">
                  <div class="modal-content" id="video_iframe">
                    <div class="modal-header">
                      <h4 class="modal-title"></h4>
                      <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">×</span></button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div class="col-12 col-md-12 col-lg-5 col-xl-4 mt-2 mt-md-5">

        <h1
          class="poppins bigtitle textblack center-text-component mt-3 mt-md-2 mt-lg-3 ml-0 ml-lg-2">{{producto.titulo}}</h1>
        <p class="roboto textblack mb-5 ml-2 center-text-component" style="font-size: 130%;">${{producto.precio_ahora}}
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-tags ml-1"
               viewBox="0 0 16 16">
            <path
              d="M3 2v4.586l7 7L14.586 9l-7-7H3zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2z"/>
            <path
              d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1v5.086z"/>
          </svg>
        </p>


        <form class="col-12 col-md-12 col-lg-11" #carritoForm="ngForm" (ngSubmit)="add_to_cart(carritoForm)">
          <div class="form-group">
            <label class="poppins smalltext textblack" for="exampleFormControlSelect1">Seleccioná el color</label>
            <select class="form-control" id="exampleFormControlSelect1" name="color_to_card" #color_to_card
                    [(ngModel)]="product_information.color">
              <option value="" disabled selected>Seleccioná color</option>
              <option *ngFor="let color of colores; first as isFirst"
                      value="{{color.titulo}}">{{color.titulo}}</option>
<!--              <option value="Otro">Otro</option>-->

            </select>
            <div class="invalid-tooltip" style="display: block;" *ngIf="color_error">Seleccioná el color
            </div>

            <!--<input *ngIf="color_to_card.value == 'Otro'" class="form-control mt-2" type="text"
                   placeholder="Especifique un color" name="other_color" #other_color ngModel>-->

            <div class="form-group d-flex align-items-center mt-2">
              <input type="number" class="form-control mr-3" min="1" name="cantidad_to_cart" id="cantidad_to_cart"
                     value="1" style="width: 5rem;" [(ngModel)]="product_information.cantidad">
              <div class="invalid-feedback" *ngIf="err_stock" style="display: block;">{{err_stock}}</div>
              <select class="custom-select" id="product-size" name="selector_to_cart" required
                      [(ngModel)]="product_information.talla">
                <option value="" disabled selected>Escogé tu talla</option>
                <option value="{{selector.titulo}}" *ngFor="let selector of selectores">{{selector.titulo}}</option>
              </select>
              <div class="invalid-tooltip" style="display: block;" *ngIf="selector_error">Seleccioná alguna opción por
                favor.
              </div>
            </div>
            <a class="nav-link-style poppinsbold smalltext ml-1" data-toggle="modal"
               data-target="#tablatallas" style="cursor: pointer;"><i
              class="czi-ruler lead align-middle mr-1 mt-n1"></i>Ver formato de tallas</a>
          </div>
          <div class="form-group d-flex align-items-center p-2">
            <button class="btn btn-dark btn-block" type="submit"><i class="czi-bag font-size-lg mr-2"></i>Añadilo al
              carrito
            </button>
          </div>
        </form>


        <div class="accordion mb-4 p-2" id="productPanels">

          <div class="card">
            <div class="card-header">
              <h3 class="accordion-heading poppinsbold" style="font-size: 13px; letter-spacing: 1px;"><a
                href="#productInfo" role="button" data-toggle="collapse"
                aria-expanded="true" aria-controls="productInfo"><i
                class="czi-list text-muted font-size-lg align-middle mt-n1 mr-2"></i>Descripción del producto<span
                class="accordion-indicator"><i data-feather="chevron-up"></i></span></a></h3>
            </div>
            <div class="collapse show" id="productInfo" data-parent="#productPanels">
              <div class="card-body">

                <p class="poppins" style="font-size: 13px; letter-spacing: 1px;">{{producto.info_short}}</p>

              </div>
            </div>
          </div>


          <!--   <div class="card">
               <div class="card-header">
                 <h3 class="accordion-heading poppinsbold" style="font-size: 13px; letter-spacing: 1px;"><a class="collapsed"
                                                                                                         href="#shippingOptions"
                                                                                                         role="button"
                                                                                                         data-toggle="collapse"
                                                                                                         aria-expanded="true"
                                                                                                         aria-controls="shippingOptions"><i
                   class="czi-delivery text-muted lead align-middle mt-n1 mr-2"></i>Opciones de envío<span
                   class="accordion-indicator"><i data-feather="chevron-up"></i></span></a></h3>
               </div>
               <div class="collapse" id="shippingOptions" data-parent="#productPanels">
                 <div class="card-body font-size-sm">

                   <ng-container *ngFor="let item of postales;let primero = first; let ultimo = last">
                     <div class="d-flex justify-content-between border-bottom pb-2" *ngIf="primero">
                       <div>
                         <div class="font-weight-semibold text-dark">{{item.titulo}}</div>
                         <div class="font-size-sm text-muted">{{item.tiempo}}</div>
                       </div>
                       <div>${{item.precio}}</div>
                     </div>
                     <div class="d-flex justify-content-between border-bottom py-2" *ngIf="!primero && !ultimo ">
                       <div>
                         <div class="font-weight-semibold text-dark">{{item.titulo}}</div>
                         <div class="font-size-sm text-muted">{{item.tiempo}}</div>
                       </div>
                       <div>${{item.precio}}</div>
                     </div>
                     <div class="d-flex justify-content-between py-2" *ngIf="ultimo ">
                       <div>
                         <div class="font-weight-semibold text-dark">{{item.titulo}}</div>
                         <div class="font-size-sm text-muted">{{item.tiempo}}</div>
                       </div>
                       <div>${{item.precio}}</div>
                     </div>
                   </ng-container>


                 </div>
               </div>
             </div>-->


          <div class="card">
            <div class="card-header">
              <h3 class="accordion-heading poppinsbold" style="font-size: 13px; letter-spacing: 1px;"><a class="collapsed"
                                                                                                      href="#payment"
                                                                                                      role="button"
                                                                                                      data-toggle="collapse"
                                                                                                      aria-expanded="true"
                                                                                                      aria-controls="#payment"><i
                class="czi-card text-muted lead align-middle mt-n1 mr-2"></i>Pago seguro<span
                class="accordion-indicator"><i data-feather="chevron-up"></i></span></a></h3>
            </div>
            <div class="collapse" id="payment" data-parent="#productPanels">
              <div class="card-body font-size-sm">
                <p class="poppins regulartext textblack">Podés pagar con tarjeta de bébito o crédito, SINPE MÓVIL o PayPal.</p>
              </div>
            </div>
          </div>

        </div>

      </div>


    </div>
  </div>

  <div class="container mt-5">
    <div class="row">
      <div class="col-12 rosecolor mb-5" data-aos="fade-right" data-aos-once="true">
        <div class="container-fluid">
          <div class="row">

            <div class="col-12 col-md-12 col-lg-6 col-xl-6 remove-all-margin-padding">
              <h4 class="bebas title_rosebox textwhite mt-4 center-text-component" style="line-height: 0.8;">Sobre el material que
                utilizamos</h4>
              <p class="poppinsbold regulartext textblack center-text-component">Atrevete a lucir diseño nacional con
                prendas orgánicas y sé parte de las muchas mujeres que creen en la moda sostenible, responsable y
                exclusiva.</p>
            </div>

            <div class="col-6 col-md-6 col-lg-3 col-xl-3 mt-4 remove-all-margin-padding">
              <div class="container-fluid">
                <div class="row">

                  <div class="col-6 col-md-4 col-lg-5 col-xl-5 ">
                    <img class="icon-rosebox ml-md-3 mt-3 mt-md-0" src="assets/img/icons/iconwhitere.png" style="">
                  </div>
                  <div class="col-6 col-md-8 col-lg-7 col-xl-7 column-data-rosebox">
                    <p class="roboto hugetitle textwhite mt-4" style="line-height: 0.1; font-weight: 500;">{{categoria.recyclingPercentage}}%</p>
                    <p class="poppins textwhite data-rosebox" style=""><strong>Reciclado</strong></p>
                  </div>

                </div>
              </div>
            </div>

            <div class="col-6 col-md-6 col-lg-3 col-xl-3 mt-4 remove-all-margin-padding">
              <div class="container-fluid">
                <div class="row">

                  <div class="col-6 col-md-4 col-lg-5 col-xl-5">
                    <img class="icon-rosebox ml-md-3 mt-3 mt-md-0" src="assets/img/icons/iconwhitegre.png" style="">
                  </div>
                  <div class="col-6 col-md-8 col-lg-7 col-xl-7 column-data-rosebox">
                    <p class="roboto hugetitle textwhite mt-4" style="line-height: 0.1; font-weight: 500;">{{categoria.organicPercentage}}%</p>
                    <p class="poppins textwhite data-rosebox" style=""><strong>Orgánico</strong></p>
                  </div>

                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section class="graycolor">

    <div class="container pt-5 pb-5" >
      <h2 class="poppins hugetext text-center pb-4">Quizás te interese</h2>
      <div class="row">
        <div class="col-6 col-md-3 col-lg-3 text-center mb-5 mt-0 mt-md-3"
             *ngFor="let slide of news_productos; let i=index">
          <img  [src]="over[i] ? url+'productos_img_data/resources/thumbnails/'+slide.hoverPoster:  url+'productos_img_data/resources/thumbnails/'+slide.poster"
                [routerLink]="['/producto/',slide.slug]"
                (mouseover)="over[i] = true" (mouseout)="over[i] = false"  style="cursor: pointer;">
          <h4 class="poppinsbold regulartext textblack mt-2" style="margin-bottom: 1%"><a class="poppins regulartext textblack"
                                                                                          style="color: black;"
                                                                                          [routerLink]="['/producto/',slide.slug]">{{slide.titulo.substr(0, 68)}}</a>
          </h4>
          <p class="roboto bigtext textblack mt-2 mt-md-2 mt-lg-2 mt-xl-2 mb-2 mb-md-2 mb-lg-2 mb-xl-2"
             style="margin-bottom: 2%">$ {{slide.precio_ahora}}</p>
          <a [routerLink]="['/producto/',slide.slug]">
            <button type="button" class="btn btn-dark btn-sm" style="">Ver producto
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
              </svg>
            </button>
          </a>
        </div>
      </div>
    </div>

</section>


<app-foot></app-foot>


<!-- Modal -->
<div class="modal fade" id="tablatallas" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Información de formato de tallas</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
