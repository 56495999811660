<app-aside></app-aside>


<div class="page-title-overlap pt-4" style="background-color: #262626;">
  <app-jumbo></app-jumbo>
</div>

<div class="container mb-5 pb-3">
  <div class="bg-light box-shadow-lg rounded-lg overflow-hidden">
    <div class="row">
      <!-- Sidebar-->
      <aside class="col-lg-4">
        <app-menu></app-menu>
      </aside>
      <!-- Content-->
      <section class="col-lg-8 pt-lg-4 mb-3">
        <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
          <div class="row">


            <div class="col-lg-12">
              <div class="border-bottom mt-lg-2 pb-2">
                <h2 class="mt-lg-4">Hitos</h2>
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                  <p class="text-muted">Cree, edite y elimine los hitos que se mostrarán en su página.</p>
                </div>
              </div>
            </div>
            <div *ngIf="msm_success" (click)="close_alert()" class="alert alert-success alert-dismissible fade show col-12"
                 role="alert">
              {{msm_success}}
              <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span
                aria-hidden="true">×</span></button>
            </div>
            <div class="col-lg-12">
              <form #hitoForm="ngForm" (ngSubmit)="onSubmit(hitoForm)">
                <div class="card border-0 box-shadow">
                  <div class="card-body">
                    <div class="tab-content" style="margin-top: 20px;">
                      <div class="tab-pane fade active show">
                        <div class="form-group row align-items-center">
                          <div class="col-md-12">
                            <h3 class="smalltext">Título del hito</h3>
                            <input class="form-control" type="text" placeholder="Agregar título" name="title"
                                   maxlength="50" [(ngModel)]="milestones.title">
                          </div>
                        </div>
                        <div class="form-group row align-items-center">
                          <div class="col-md-12">
                            <h3 class="smalltext mt-3">Descripción del hito</h3>
                            <textarea class="form-control" id="textarea-input" rows="5"
                                      placeholder="Agregar descripción"
                                      name="description" [(ngModel)]="milestones.description"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-primary mb-2 mr-1" type="submit">Añadir</button>
                  </div>
                </div>
              </form>
            </div>

            <div *ngIf="hitos?.length > 0">
              <div class="col-lg-12 pb-2 mt-4" *ngFor="let item of hitos">
                <div class="card">
                  <div class="card-header">
                    <h3 class="product-title hugetext" style="margin-bottom: 0px;">
                      <a>{{item.title}}</a></h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="media-body pt-2">
                          <div class="font-size-lg text-accent pt-2"><span
                            class="text-muted mr-2">{{item.description}}</span></div>
                        </div>
                      </div>

                      <div class="col-lg-12 mt-4">
                        <div class="btn-toolbar" role="toolbar" aria-label="Settings toolbar">
                          <div class="btn-group mr-2 mb-2" role="group" aria-label="Settings group">

                            <a data-toggle="modal" [attr.data-target]="'#editmilestone-'+item._id"
                               class="btn btn-warning mr-2">
                              <i class="czi-star"></i> Editar
                            </a>

                            <a data-toggle="modal" [attr.data-target]="'#modal-'+item._id"
                               class="btn btn-dark btn-icon ">
                              <i class="czi-trash"></i> &nbsp; Eliminar
                            </a>

                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="modal fade" [attr.id]="'modal-'+item._id" tabindex="-1" role="dialog"
                         style="display: none;" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h4 class="modal-title">Confirmación</h4>
                            <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                              aria-hidden="true">×</span></button>
                          </div>
                          <div class="modal-body">
                            <p class="font-size-sm">¿Desea eliminar definitivamente este hito?</p>
                          </div>
                          <div class="modal-footer">
                            <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Cancelar
                            </button>
                            <button class="btn btn-primary btn-shadow btn-sm" type="button"
                                    (click)="eliminar(item._id)">
                              Suprimir
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="modal fade" [attr.id]="'editmilestone-'+item._id" tabindex="-1" role="dialog"
                         style="display: none;" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h4 class="modal-title text-lg-left">Editar hito</h4>
                            <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                              aria-hidden="true">×</span></button>
                          </div>
                          <div class="modal-body">
                            <form #hitoform="ngForm" (ngSubmit)="update(hitoform,item._id)">
                              <div class="card border-0 box-shadow">
                                <div class="card-body">
                                  <div class="tab-content" style="margin-top: 20px;">
                                    <div class="tab-pane fade active show">
                                      <div class="form-group row align-items-center">
                                        <div class="col-md-12">
                                          <h3 class="smalltext">Título del hito</h3>
                                          <input class="form-control" type="text" name="title"
                                                 maxlength="50" [(ngModel)]="item.title">
                                        </div>
                                      </div>
                                      <div class="form-group row align-items-center">
                                        <div class="col-md-12">
                                          <h3 class="smalltext mt-3">Descripción del hito</h3>
                            <textarea class="form-control" id="textarea-input" rows="5" placeholder="description"
                                      name="description" [(ngModel)]="item.description"></textarea>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="card-footer">
                                  <button class="btn btn-success btn-sm mb-2 mr-1" type="submit">Actualizar</button>
                                  <button class="btn btn-secondary btn-sm mb-2 ml-1" type="button" data-dismiss="modal">Cancelar
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </section>
    </div>
  </div>
</div>

