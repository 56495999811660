import {Component, OnInit} from '@angular/core';

import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HitosService} from '../../../services/hito.service';
import {milestones} from '../../../models/Hito';
import {GLOBAL} from "../../../services/GLOBAL";

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-hitos',
  templateUrl: './hitos.component.html',
  styleUrls: ['./hitos.component.css']
})
export class HitosComponent implements OnInit {


  public hitos: Array<any> = [];
  public milestones = new milestones('', '', '');
  public identity;
  public id;
  public msm_error = '';
  public msm_success = '';

  constructor(
    private _userService: UserService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _hitoService: HitosService
  ) {
    this.identity = this._userService.getIdentity();
  }

  ngOnInit(): void {
    this.initData();
  }


  initData() {
    this._hitoService.listar().subscribe(
      response => {
        this.hitos = response.milestones;
      },
      error => {

      }
    );
  }

  eliminar(id) {
    this._hitoService.eliminar(id).subscribe(
      response => {

        $('#modal-' + id).modal('hide');
        $('.modal-backdrop').removeClass('show');
        $('#dark-toast').removeClass('hide');
        $('#dark-toast').addClass('show');
        setTimeout(function () {
          $("#dark-toast").fadeOut(1500);
        }, 3000);
        this.initData();

      },
      error => {

      }
    );
  }

  close_toast() {
    $('#dark-toast').removeClass('show');
    $('#dark-toast').addClass('hide');
  }

  onSubmit(hitoForm) {

    if (hitoForm.valid && this.milestones.title && this.milestones.description) {

      let data = {
        title: hitoForm.value.title,
        description: hitoForm.value.description
      };
      this._hitoService.registro(data).subscribe(
        response => {
          console.log(response);
          this.milestones = new milestones('', '', '');
          this.msm_success = 'Hito de AMAG añadida';
          this.initData();
        },
        error => {
          this.msm_error = 'Complete correctamente el formulario por favor.';
        }
      );

    } else {
      this.msm_error = 'Complete correctamente el formulario por favor.';
    }
  }

  update(hitoform, _id) {

    if (hitoform.valid) {

      let data = {
        _id: _id,
        title: hitoform.value.title,
        description: hitoform.value.description
      };




      this._hitoService.update(data).subscribe(
        response => {
          console.log(response);
          this.msm_success = 'Hito de AMAG añadida';

          $('#Modal').modal('hide');
          $('.modal-backdrop').remove();
          $(document.body).removeClass("modal-open");
          this.initData();

        },

        error => {
          this.msm_error = error;
        }
      );

    } else {
      this.msm_error = 'Complete correctamente el formulario por favor.';
    }


  }

  close_alert(){
    this.msm_success = '';
  }


}
