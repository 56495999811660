import { Component, OnInit } from '@angular/core';
import {PreguntaService} from '../../services/pregunta.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FAQComponent implements OnInit {

  public preguntas: Array<any> = [];

  constructor(
    private _preguntaService: PreguntaService
  ) {}

  ngOnInit(): void {
    this._preguntaService.listar().subscribe(
      response=>{
        this.preguntas = response.preguntas;
      },
      error =>{

      }
    );
  }

}
