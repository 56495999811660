import {Routes, RouterModule} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {AuthComponent} from './components/auth/auth.component';
import {InicioComponent} from './components/inicio/inicio.component';
import {CreateCategoriaComponent} from './components/admin/categoria/create-categoria/create-categoria.component';
import {IndexCategoriaComponent} from './components/admin/categoria/index-categoria/index-categoria.component';
import {IndexArticleComponent} from './components/admin/article/index-article/index-article.component';
import {CreateArticleComponent} from './components/admin/article/create-article/create-article.component';
import {EditCategoriaComponent} from './components/admin/categoria/edit-categoria/edit-categoria.component';
import {EditArticleComponent} from './components/admin/article/edit-article/edit-article.component';
import {CreateProductoComponent} from './components/admin/producto/create-producto/create-producto.component';
import {CreateMarcaComponent} from './components/admin/marca/create-marca/create-marca.component';
import {IndexMarcaComponent} from './components/admin/marca/index-marca/index-marca.component';
import {EditMarcaComponent} from './components/admin/marca/edit-marca/edit-marca.component';
import {PortadaComponent} from './components/admin/config/general/portada.component';
import {IndexProductoComponent} from './components/admin/producto/index-producto/index-producto.component';
import {EditProductoComponent} from './components/admin/producto/edit-producto/edit-producto.component';
import {GaleriaProductoComponent} from './components/admin/producto/galeria-producto/galeria-producto.component';
import {SelectorProductoComponent} from './components/admin/producto/selector-producto/selector-producto.component';
import {ColorProductoComponent} from './components/admin/producto/color-producto/color-producto.component';
import {PapeleraProductoComponent} from './components/admin/producto/papelera-producto/papelera-producto.component';
import {ProductosComponent} from './components/productos/productos.component';
import {ShowProductoComponent} from './components/show-producto/show-producto.component';
import {CollectionsComponent} from './components/collections/collections.component';
import {AboutdesignerComponent} from './components/aboutdesigner/aboutdesigner.component';
import {FAQComponent} from './components/faq/faq.component';
import {ArticlesComponent} from './components/articles/articles.component';
import {ShowArticleComponent} from './components/show-article/show-article.component';
import {CarritoService} from './services/carrito.service';
import {CarritoComponent} from './components/carrito/carrito.component';
import {PerfilComponent} from './components/cuenta/perfil/perfil.component';
import {DireccionesComponent} from './components/cuenta/direcciones/direcciones.component';
import {CuponComponent} from './components/admin/cupon/cupon.component';
import {PostalComponent} from './components/admin/postal/postal.component';
import {IndexOrdenesComponent} from './components/cuenta/ordenes/index-ordenes/index-ordenes.component';
import {TrackOrdenComponent} from './components/cuenta/ordenes/track-orden/track-orden.component';
import {DetalleOrdenComponent} from './components/cuenta/ordenes/detalle-orden/detalle-orden.component';
import {IndexTicketComponent} from './components/cuenta/ordenes/index-ticket/index-ticket.component';
import {ChatTicketComponent} from './components/cuenta/ordenes/chat-ticket/chat-ticket.component';
import {AdminVentasComponent} from './components/admin/ventas/admin-ventas/admin-ventas.component';
import {InvoiceComponent} from './components/admin/ventas/invoice/invoice.component';
import {AdminDetalleventasComponent} from './components/admin/ventas/admin-detalleventas/admin-detalleventas.component';
import {ContactoComponent} from './components/contacto/contacto.component';
import {ErrorComponent} from './components/error/error.component';
import {ContactoAdminComponent} from './components/admin/contacto-admin/contacto-admin.component';
import {DashboardComponent} from './components/admin/dashboard/dashboard.component';
import {IndexIngresoComponent} from './components/admin/ingreso/index-ingreso/index-ingreso.component';
import {CreateIngresoComponent} from './components/admin/ingreso/create-ingreso/create-ingreso.component';
import {DetalleIngresoComponent} from './components/admin/ingreso/detalle-ingreso/detalle-ingreso.component';
import {RecoveryComponent} from './components/recovery/recovery.component';
import {PreguntaComponent} from './components/admin/pregunta/pregunta.component';
import {HitosComponent} from "./components/admin/hitos/hitos.component";


const appRoute: Routes = [
  {path: '', component: InicioComponent},
  {path: 'home/:subplace', component: InicioComponent},
  {path: 'login', component: AuthComponent},
  {path: 'restablecer-contraseña', component: RecoveryComponent},

  {path: 'contacto', component: ContactoComponent},
  {path: 'dashboard', component: DashboardComponent},

  {path: 'productos', component: ProductosComponent},
  {path: 'colecciones', component: CollectionsComponent},
  {path: 'preguntasfrecuentes', component: FAQComponent},
  {path: 'articulos', component: ArticlesComponent},
  {path: 'articulo/:slug', component: ShowArticleComponent},
  {path: 'sobrenosotros', component: AboutdesignerComponent},
  {path: 'producto/:slug', component: ShowProductoComponent},
  {path: 'productos/search/:filter', component: ProductosComponent},
  {path: 'productos/:categoria', component: ProductosComponent},
  // {path: 'productos/:categoria/subcategoria/:subcategoria', component: ProductosComponent},

  {path: 'cuenta/shop-cart', component: CarritoComponent},
  {path: 'cuenta/perfil', component: PerfilComponent},
  {path: 'cuenta/direcciones', component: DireccionesComponent},
  {path: 'cuenta/ordenes', component: IndexOrdenesComponent},
  {path: 'cuenta/ordenes/tracking/:id', component: TrackOrdenComponent},
  {path: 'cuenta/ordenes/detalles/:id', component: DetalleOrdenComponent},
  {path: 'cuenta/ordenes/chat/:id', component: ChatTicketComponent},

  {
    path: 'admin', children: [
      {path: 'categorias', component: IndexCategoriaComponent},
      {path: 'categoria/registro', component: CreateCategoriaComponent},
      {path: 'categoria/:id', component: EditCategoriaComponent},

      {path: 'articles', component: IndexArticleComponent},
      {path: 'article/new', component: CreateArticleComponent},
      {path: 'article/:id', component: EditArticleComponent},

      {path: 'productos', component: IndexProductoComponent},
      {path: 'producto/papelera', component: PapeleraProductoComponent},
      {path: 'producto/registro', component: CreateProductoComponent},
      {path: 'producto/:id', component: EditProductoComponent},
      {path: 'producto/:id/galeria', component: GaleriaProductoComponent},
      {path: 'producto/:id/selector', component: SelectorProductoComponent},
      {path: 'portada', component: PortadaComponent},
      {path: 'preguntas', component: PreguntaComponent},
      {path: 'hitos', component: HitosComponent},

      {path: 'producto/:id/color', component: ColorProductoComponent},
      // {path: 'cupones', component: CuponComponent},
      {path: 'medios-postales', component: PostalComponent},


      {path: 'ventas/modulo', component: AdminVentasComponent},
      {path: 'ventas/modulo/invoice/:id', component: InvoiceComponent},
      {path: 'venta/modulo/detalle/:id', component: AdminDetalleventasComponent},

      {path: 'mensajes', component: ContactoAdminComponent},

      {path: 'ingresos', component: IndexIngresoComponent},
      {path: 'ingresos/registro', component: CreateIngresoComponent},
      {path: 'ingresos/detalle/:id', component: DetalleIngresoComponent},
    ]
  },
  {path: '**', component: ErrorComponent},

  /*   { path: '**', redirectTo: '/'} */
];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoute, {relativeLinkResolution: 'legacy'});


