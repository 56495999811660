<div
  class="container d-flex flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-between align-items-center pt-2">
  <div class="media media-ie-fix align-items-center pb-3">
    <div class="img-thumbnail  position-relative" style="width: 6.375rem;"><a [routerLink]="['/home/inicio']"><img class="rounded-circle"
                                                                                src="../../../../assets/img/admin.png"
                                                                                alt="Createx Studio"></a></div>
    <div class="media-body pl-3">
      <h3 class="text-light font-size-lg mb-0">Panel de administración</h3><span
      class="d-block text-light font-size-ms opacity-60 py-1">Welcome to your E-commerce Dashboard</span>
    </div>
  </div>

</div>
