<app-nav></app-nav>


<section class="rosecover">

</section>

<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="bebas rosetitle textwhite margin-text-cover">AMAG Y SU ESENCIA</h1>
        <span class="decor-cover"></span>
      </div>
    </div>
  </div>
</section>


<section class="graycolor">

  <div class="container">
    <div class="row">

      <div class="col-12 col-md-4">
        <picture>
          <source  srcset="assets/img/nolys-about.webp" type="image/webp">
          <source srcset="assets/img/nolys-about.jpg" type="image/jpg">
          <img class="mt-5 photo-nolys" srcset="assets/img/nolys-about.webp" src="assets/img/nolys-about.jpg" style=" ;height: auto; width: 100%;">
        </picture>
        <h2 class="poppinsbold hugetitle textblack mt-4 center-text-component"><strong>Nolys Rodríguez</strong></h2>
        <p class="poppins regulartext textblack ">Cada diseño tiene una historia, la cual es elaborada con respeto y
          detalladamente de manera versátil, accesible y sostenible.</p>
      </div>

      <div class="col-12 col-md-8 mt-5">


        <div>

          <mat-stepper orientation="vertical">

            <mat-step *ngFor="let item of hitos">
              <ng-template matStepLabel>
                <mat-icon>brightness_1</mat-icon>
              </ng-template>
              <p class="poppinsbold regulartext textblack titlestepper"><strong>{{item.title}}</strong></p>
              <p class="poppins regulartext">{{item.description}}</p>
            </mat-step>

          </mat-stepper>

        </div>
      </div>

    </div>
  </div>

</section>


<section class="graycolor">
  <div class="container">
    <div class="row">


      <div class="col-12 text-center">
        <p class="modernline frasesize textblack mt-5">Cada colección tiene su magia</p>
        <p class="poppins regulartext textblack mb-4"></p>
      </div>

      <div class="col-12 col-md-12 col-lg-6 mb-5 none-mobile" *ngFor="let categoria of categorias;let indice=index">
        <div class="card text-white"
             style="border-radius: 0 !important; border-color: transparent; background-color: transparent;">
          <img [src]="url+'categoria/resources/thumbnails/'+ categoria.banner" class="card-img" alt="..."
               style="border-radius: 0 !important; background-color: #b1a0a2">
          <div class="card-img-overlay">
            <h5 class="card-title poppinsbold hugetext textwhite margin-top-card"
                style="">{{categoria.nombre}}</h5>
            <a [routerLink]="['/productos/'+categoria.nombre.toLowerCase().trim()]">
              <button type="button" class="btn btn-light btn-sm">Ver colección
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                </svg>
              </button>
            </a>
          </div>
        </div>
      </div>

      <div class="col-12 mb-5 none-desktop">

        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">


            <div class="carousel-item" *ngFor="let categoria of categorias; first as isFirst"
                 [ngClass]="{'active' : isFirst}">
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <div class="card  text-white" style="border-radius: 0 !important; background-color: transparent;">
                      <img [src]="url+'categoria/resources/thumbnails/'+ categoria.mobile_banner" class="card-img" alt="..."
                           style="border-radius: 0 !important; background-color: #b1a0a2;">
                      <div class="card-img-overlay">
                        <h5 class="card-title poppinsbold textwhite margin-top-card"
                            style="line-height: 1.1; font-size: 110%;">{{categoria.nombre}}</h5>
                        <a [routerLink]="['/productos/'+categoria.nombre.toLowerCase().trim()]">
                          <button type="button" class="btn btn-light btn-sm">Ver colección
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                              <path fill-rule="evenodd"
                                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                            </svg>
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
          <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>


    </div>
  </div>
</section>


<app-foot></app-foot>
