<app-aside></app-aside>


<div class="page-title-overlap pt-4" style="background-color: #262626;">
  <app-jumbo></app-jumbo>
</div>

<div class="container mb-5 pb-3">
  <div class="bg-light box-shadow-lg rounded-lg overflow-hidden">
    <div class="row">
      <!-- Sidebar-->
      <aside class="col-lg-4">
        <app-menu></app-menu>
      </aside>
      <!-- Content-->
      <section class="col-lg-8 pt-lg-4 pb-4 mb-3">
        <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
          <div class="row">
            <div class="col-lg-5 form-group">
                <form #postalForm="ngForm" (ngSubmit)="onSubmit(postalForm)">
                <div class="card">
                    <div class="card-header">
                        <b>Nuevo medio</b>
                    </div>
                    <div class="card-body">

                            <div class="row">

                                <div class="col-lg-12 form-group" >
                                    <label for="normal-input">Empresa o Tipo de envio</label>
                                    <input class="form-control" type="text" name="titulo" [(ngModel)]="postal.titulo" required placeholder="Empresa o tipo de envío">
                                </div>
                                <div class="col-lg-12 form-group" >
                                    <label for="normal-input">Tiempo estimado</label>
                                    <input class="form-control" type="text" name="tiempo" [(ngModel)]="postal.tiempo" required placeholder="10 - 15 días">
                                </div>
                                <div class="col-lg-12 form-group" >
                                    <label for="normal-input">Precio</label>
                                    <input class="form-control" type="number" name="precio" [(ngModel)]="postal.precio" required placeholder="Precio del envio">
                                </div>
                                <div class="col-lg-12 form-group" >
                                  <label for="normal-input">Días</label>
                                  <input class="form-control" type="number" name="dias" [(ngModel)]="postal.dias" required placeholder="En cuantos días llega aprox.">
                              </div>
                            </div>

                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary btn-sm">Crear</button>
                    </div>
                </div>
                </form>
            </div>
            <div class="col-lg-7 form-group">
                <div class="row">
                    <div class="col-lg-12" *ngIf="msm_error">
                        <div class="alert alert-primary alert-danger fade show" role="alert">
                            <span class="font-weight-medium">Mensaje:</span> {{msm_error}}
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="close_alert()">
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="table-responsive table-sm">
                            <table class="table table-sm">
                              <thead class="thead-dark">
                                <tr>

                                  <th>Empresa</th>
                                  <th>Tiempo</th>
                                  <th>Precio</th>
                                  <th>OP</th>
                                </tr>
                              </thead>
                              <tbody *ngFor="let item of postales">
                                <tr>
                                    <td style="font-size: 13px;">{{item.titulo}}</td>
                                    <td style="font-size: 13px;">{{item.tiempo}}</td>
                                    <td style="font-size: 13px;">{{item.precio}}</td>
                                    <td class="text-center">
                                        <a class="nav-link-style text-danger" data-toggle="modal" [attr.data-target]="'#delete-'+item._id" style="cursor:pointer">
                                            <div class="czi-trash"></div>
                                          </a>
                                          <div class="modal fade" [attr.id]="'delete-'+item._id" tabindex="-1" role="dialog" style="display: none;" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered" role="document">
                                              <div class="modal-content">
                                                <div class="modal-header">
                                                  <h4 class="modal-title">Confirmación</h4>
                                                  <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                                </div>
                                                <div class="modal-body">
                                                  <p class="font-size-sm">¿Desea eliminar definitivamente el regsitro?</p>
                                                </div>
                                                <div class="modal-footer">
                                                  <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">Cancelar</button>
                                                  <button class="btn btn-primary btn-shadow btn-sm" type="button" (click)="eliminar(item._id)">Suprimir</button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                    </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                    </div>
                </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  </div>
</div>

