export class Article{
  constructor(
    public _id: string,
    public nombre: string,
    public body: string,
    public banner: string,
    public mobile_banner: string
  ){
  }
}
