<app-nav></app-nav>



<section *ngIf="portada?.tipo == 'Portada'">
  <div *ngIf="desktopFileType=='image'" class="card bg-dark text-white none-mobile" style="border-radius: 0 !important; background-color: transparent !important;">
    <img [src]="url+'portada/resources/thumbnails/' + portada?.portada" class="card-img" alt="..." style="border-radius: 0 !important;">
    <div class="card-img-overlay">
    </div>
  </div>

  <div *ngIf="mobileFileType=='image'" class="card bg-dark text-white none-desktop" style="border-radius: 0 !important; background-color: transparent !important;">
    <img [src]="url+'portada/resources/thumbnails/' + portada?.portadaMobile" class="card-img" alt="..." style="border-radius: 0 !important;">
    <div class="card-img-overlay">
    </div>
  </div>

  <div *ngIf="desktopFileType=='video'" class="card bg-dark text-white none-mobile" style="border-radius: 0 !important; background-color: transparent !important;">
    <video style="width: 100%;" autoplay loop  [muted]="true">
      <source [src]="url+'portada/resources/thumbnails/' + portada?.portada" [type]="'video/' + this.portada.portada.split('.').pop()">
    </video>
    <div class="card-img-overlay">
    </div>
  </div>


  <div *ngIf="mobileFileType=='video'" class="card bg-dark text-white none-desktop" style="border-radius: 0 !important; background-color: transparent !important;">
    <video style="width: 100%;" autoplay loop  [muted]="true">
      <source [src]="url+'portada/resources/thumbnails/' + portada?.portadaMobile" [type]="'video/' + this.portada.portadaMobile.split('.').pop()">
    </video>
    <div class="card-img-overlay">
    </div>
  </div>
</section>



<section class="second-cover" *ngIf="portada?.tipo == 'Slider'">
  <div id="portadaamagdesktop" class="carousel slide" *ngIf="!isMobile"  data-ride="carousel">


    <div class="carousel-inner">
      <div class="carousel-item active" data-interval="4000">
        <img [src]="url+'portada/resources/thumbnails/' + portada?.slider1" class="d-block w-100" alt="...">
      </div>

      <div class="carousel-item" data-interval="4000">
        <img [src]="url+'portada/resources/thumbnails/' + portada?.slider2" class="d-block w-100" alt="...">
      </div>

      <div class="carousel-item" data-interval="4000">
        <img [src]="url+'portada/resources/thumbnails/' + portada?.slider3" class="d-block w-100" alt="...">
      </div>
    </div>


    <a class="carousel-control-prev" href="#portadaamagdesktop" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a id="nextSlideDesktop" class="carousel-control-next" href="#portadaamagdesktop" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>



  <div id="portadaamagmobile" class="carousel slide" *ngIf="isMobile" data-ride="carousel">


    <div class="carousel-inner">
      <div class="carousel-item active" data-interval="4500">
        <img [src]="url+'portada/resources/thumbnails/' + portada?.slider1Mobile" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item" data-interval="4500">
        <img [src]="url+'portada/resources/thumbnails/' + portada?.slider2Mobile" class="d-block w-100" alt="...">
      </div>
      <div class="carousel-item" data-interval="4500">
        <img [src]="url+'portada/resources/thumbnails/' + portada?.slider3Mobile" class="d-block w-100" alt="...">
      </div>
    </div>
    <a class="carousel-control-prev" href="#portadaamagmobile" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a id="nextSlideMobile" class="carousel-control-next" href="#portadaamagmobile" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</section>






<section class="mt-5">
  <div class="container-fluid">
    <div class="row">

      <div class="col-1 col-md-1 col-lg-2"></div>

      <div class="col-12 col-md-10 col-lg-8 text-center">
        <h1 class="frasesize modernline textblack mt-2 mb-4" style="">Colecciones sostenibles y exclusivas</h1>
        <p class="regulartext poppins textblack">Marcá tu estilo con telas que resaltan tu consciencia y compromiso con el
          ambiente.</p>
        <a [routerLink]="['/productos/'+ categoriaLatest?.nombre?.toLowerCase().trim()]">
          <button type="button" class="btn btn-outline-dark btn-sm mr-0 mr-md-2 mb-2 mb-md-0">Última colección
          </button>
        </a>
        <a [routerLink]="['/colecciones']">
          <button type="button" class="btn btn-outline-dark btn-sm ml-2 mb-2 mb-md-0">
            Todas nuestras colecciones
          </button>
        </a>
      </div>

      <div class="col-1 col-md-1 col-lg-2"></div>
    </div>
  </div>
</section>


<section class=" mt-5" style="margin-bottom: 5%;">
  <div class="container">
    <div class="row">

      <div class="col-1 col-md-0 none-desktop"></div>

      <div class="col-10 col-md-5 col-lg-4 col-xl-4 mt-2 mt-md-5">
        <h2 class="bigtitle bebas textblack mt-3 mt-md-2 mt-lg-5">{{categoriaLatest?.nombre}}</h2>
        <p class="regulartext poppins textblack">{{categoriaLatest?.description}}</p>
        <a [routerLink]="['/productos/'+ categoriaLatest?.nombre?.toLowerCase().trim()]">
          <button type="button" class="btn btn-dark btn-sm mb-5">Ver la colección
          </button>
        </a>
      </div>

      <div class="col-1 none-desktop"></div>


      <div class="col-12 col-md-7 col-lg-8 col-xl-8 mt-2 mt-md-5 none-mobile">
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">


          <div class="carousel-inner">

            <ng-container *ngFor="let item of productoLatest; let i = index; first as isFirst">
              <div class="carousel-item {{isFirst? 'active': ''}} col-12" *ngIf="i % 2 == 0">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-0 col-md-1"></div>
                    <div class="col-5 text-center">
                      <img  [src]="over[i] ? url+'productos_img_data/resources/thumbnails/'+item.hoverPoster:  url+'productos_img_data/resources/thumbnails/'+item.poster"
                            [routerLink]="['/producto/',item.slug]"
                            (mouseover)="over[i] = true" (mouseout)="over[i] = false" >
                      <p class="poppins regulartext textblack mt-2">{{item.titulo}}</p>
                      <a [routerLink]="['/producto/',item.slug]">
                        <button type="button" class="btn btn-outline-dark btn-sm">
                          Ver producto
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                               class="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                          </svg>
                        </button>
                      </a>
                    </div>
                    <div class="col-5 text-center" *ngIf="i + 1 < productoLatest?.length">
                      <img [src]="url+'productos_img_data/resources/thumbnails/'+productoLatest[i + 1].poster">
                      <p class="poppins regulartext textblack mt-2">{{productoLatest[i + 1].titulo}}</p>
                      <a [routerLink]="['/producto/',productoLatest[i + 1].slug]">
                        <button type="button" class="btn btn-outline-dark btn-sm">
                          See product
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                               class="bi bi-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                          </svg>
                        </button>
                      </a>
                    </div>
                    <div class="col-0 col-md-1"></div>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="carousel-item text-center">
              <h1 class="poppinsbold bigtitle textblack mt-5 mb-3">Podés ver todas las piezas</h1>
              <a [routerLink]="['/productos/'+ categoriaLatest?.nombre?.toLowerCase().trim()]">
                <button type="button" class="btn btn-dark btn-sm margin-bottom-btn">Ver la colección
                </button>
              </a>
            </div>
          </div>


          <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>

      <div class="col-12 col-md-8 mt-2 mt-md-5 none-desktop">

        <div id="carouselExampleControlsMobile" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner" >
            <ng-container *ngFor="let item of productoLatest; let i = index; first as isFirst">
            <div class="carousel-item {{isFirst? 'active': ''}}">
              <div class="container-fluid">
                <div class="row">

                  <div class="col-1"></div>

                  <div class="col-10 text-center">
                    <img [src]="url+'productos_img_data/resources/thumbnails/'+item.poster">
                    <p class="poppins regulartext textblack mt-2">{{item.titulo}}</p>
                    <a [routerLink]="['/producto/',item.slug]"><button type="button" class="btn btn-outline-dark btn-sm">
                      Ver producto
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg>
                    </button></a>
                  </div>

                  <div class="col-1"></div>

                </div>
              </div>
            </div>
            </ng-container>

          </div>

          <a class="carousel-control-prev" href="#carouselExampleControlsMobile" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControlsMobile" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>

      </div>

    </div>
  </div>
</section>

<section class="graycolor mt-5" #materials>

  <div class="container mt-5">
    <div class="row">

      <div class="col-12">
        <picture>
          <source  srcset="assets/img/covertextiles.webp" type="image/webp">
          <source srcset="assets/img/covertextiles.jpg" type="image/jpg">
          <img class="mt-5 mb-5" srcset="assets/img/covertextiles.webp" src="assets/img/covertextiles.jpg" style=" ;height: auto; width: 100%;">
        </picture>
      </div>


      <div class="col-0 col-md-2"></div>
      <div class="col-12 col-md-8 text-center">
        <h3 class="bebas rosetitle textblack" style="line-height: 1;">Sobre el material que utilizamos</h3>
        <p class="poppins regulartext textblack">Atrevete a lucir diseño nacional con prendas orgánicas y sé parte de las
          muchas mujeres que creen en la moda sostenible, responsable y con diseños exclusivos.</p>
      </div>
      <div class="col-0 col-md-2"></div>


      <div class="col-4 mt-4 none-mobile">
        <div class="container-fluid">
          <div class="row">

            <div class="col-4">
              <img class="ml-3" src="assets/img/icons/icongreen.png" style="width: 100%;">
            </div>
            <div class="col-8">
              <p class="roboto hugetitle textblack mt-4" style="line-height: 0.1; font-weight: 500;">{{categoriaLatest?.organicPercentage}}%</p>
              <p class="poppins textblack" style="font-size: 130%;"><strong>Orgánico</strong></p>
            </div>

          </div>
        </div>
      </div>

      <div class="col-4 mt-4 none-mobile">
        <div class="container-fluid">
          <div class="row">

            <div class="col-4">
              <img class="ml-3" src="assets/img/icons/iconrecycling.png" style="width: 100%;">
            </div>
            <div class="col-8">
              <p class="roboto hugetitle textblack mt-4" style="line-height: 0.1; font-weight: 500;">{{categoriaLatest?.recyclingPercentage}}%</p>
              <p class="poppins textblack" style="font-size: 130%;"><strong>Reciclado</strong></p>
            </div>

          </div>
        </div>
      </div>

      <div class="col-4 mt-4 none-mobile">
        <div class="container-fluid">
          <div class="row">

            <div class="col-4">
              <img class="ml-3" src="assets/img/icons/iconquality.png" style="width: 100%;">
            </div>
            <div class="col-8">
              <p class="roboto hugetitle textblack mt-4" style="line-height: 0.3; font-weight: 500;">Materiales</p>
              <p class="poppins textblack" style="font-size: 130%;"><strong>de calidad</strong></p>
            </div>

          </div>
        </div>
      </div>

      <!--      Icons with information for mobile-->
      <div class="col-6 none-desktop">
        <div class="container-fluid">
          <div class="row">

            <div class="col-8">
              <img class="" src="assets/img/icons/icongreen.png" style="width: 70%;">
            </div>
            <div class="col-4">
              <p class="roboto textblack"
                 style="line-height: 0.1; font-weight: 800; font-size: 200%; margin-left: -170%; margin-top: 110%;">{{categoriaLatest?.organicPercentage}}%</p>
            </div>
            <div class="col-12">
              <p class="poppins textblack" style="font-size: 150%;"><strong>Orgánico</strong></p>
            </div>

          </div>
        </div>
      </div>

      <div class="col-6 none-desktop">
        <div class="container-fluid">
          <div class="row">

            <div class="col-8">
              <img class="" src="assets/img/icons/iconrecycling.png" style="width: 70%;">
            </div>
            <div class="col-4">
              <p class="roboto textblack"
                 style="line-height: 0.4; font-weight: 800; font-size: 200%; margin-left: -170%; margin-top: 110%;">{{categoriaLatest?.recyclingPercentage}}%</p>
            </div>
            <div class="col-12">
              <p class="poppins textblack" style="font-size: 150%;"><strong>Reciclado</strong></p>
            </div>

          </div>
        </div>
      </div>


      <div class="col-12 text-center mt-4 mb-5 mt-md-5 mb-md-5">
        <h3 class="modernline frasesize textblack mb-2">Sé parte de la ética sostenible</h3>
      </div>


    </div>
  </div>
</section>


<section class="">
  <div class="container">
    <div class="row">

      <div class="col-12 col-md-6">
        <h4 class="bebas hugetitle textblack designerposition">Conocé a</h4>
        <p class="nolystitle poppinsbold textblack mt-2 mt-md-0" style="">Nolys Rodríguez
        </p>
        <p class="poppins regulartext textblack">Detrás de cada diseño está nuestra diseñadora Nolys, quien cuenta una
          historia en cada pieza que confecciona para AMAG con amor y respeto al planeta. </p>
        <a [routerLink]="['/sobrenosotros']">
          <button type="button" class="btn btn-outline-dark">Ver más <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
          </svg>
          </button>
        </a>
      </div>

      <div class="col-12 col-md-6">
        <picture>
          <source  srcset="assets/img/nolys-about.webp" type="image/webp">
          <source srcset="assets/img/nolys-about.jpg" type="image/jpg">
          <img class="mt-5 mb-5" srcset="assets/img/nolys-about.webp" src="assets/img/nolys-about.jpg" style="width: 78%;margin-left: 7.5%;">
        </picture>

      </div>


    </div>
  </div>
</section>


<section class="rosecolor texturespets">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h4 class="frasesize textwhite modernline text-center mb-3 mb-md-4 margin-steps " style="">Tu estilo nos inspira
        </h4>
      </div>

      <div class="col-3 text-center mb-5" *ngIf="!isMobile">
        <img class="mt-5" src="assets/img/icons/iconstep1.png" style="width: 30%;">
        <p class="bebas hugetext textwhite" style="line-height: 0.9;">Elegí tu diseño</p>
        <p class="poppins smalltext textwhite">Te ofrecemos diseños de calidad con variedad de telas y colores.</p>
      </div>

      <div class="col-3 text-center mb-5" *ngIf="!isMobile">
        <img class="mt-5" src="assets/img/icons/iconstep2.png" style="width: 30%;">
        <p class="bebas hugetext textwhite" style="line-height: 0.9;">Seleccioná tu talla</p>
        <p class="poppins smalltext textwhite">Elegí las medidas de tu prenda.</p>
      </div>

      <div class="col-3 text-center mb-5" *ngIf="!isMobile">
        <img class="mt-5" src="assets/img/icons/iconstep3.png" style="width: 30%;">
        <p class="bebas hugetext textwhite" style="line-height: 0.9;">Nosotros lo confeccionamos </p>
        <p class="poppins smalltext textwhite">Hacemos de tu diseño una pieza real con telas sostenibles y duraderas.</p>
      </div>

      <div class="col-3 text-center mb-5" *ngIf="!isMobile">
        <img class="mt-5" src="assets/img/icons/iconstep4.png" style="width: 30%;">
        <p class="bebas hugetext textwhite" style="line-height: 0.9;">Disfrutá tu diseño exclusivo</p>
        <p class="poppins smalltext textwhite" style="margin-bottom: 20%;">Una vez listo el diseño, estará pronto en tus
          manos. </p>
      </div>

      <div class="col-12 mb-5" *ngIf="isMobile">
        <div id="carouselExampleControlsicons" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">

            <div class="carousel-item active">
              <div class="container-fluid">
                <div class="row">

                  <div class="col-1"></div>

                  <div class="col-10 text-center">
                    <img class="mt-5" src="assets/img/icons/iconstep1.png" style="width: 30%;">
                    <p class="bebas bigtitle textwhite" style="line-height: normal;">Elegí tu diseño</p>
                    <p class="poppins regulartext textwhite">Te ofrecemos diseños de calidad con variedad de telas y
                      colores.</p>
                  </div>

                  <div class="col-1 "></div>
                </div>
              </div>
            </div>


            <div class="carousel-item">
              <div class="container-fluid">
                <div class="row">

                  <div class="col-1"></div>

                  <div class="col-10 text-center">
                    <img class="mt-5" src="assets/img/icons/iconstep2.png" style="width: 30%;">
                    <p class="bebas bigtitle textwhite" style="line-height: normal;">Seleccioná tu talla</p>
                    <p class="poppins regulartext textwhite">Elegí las medidas de tu prenda.</p>
                  </div>

                  <div class="col-1 "></div>
                </div>
              </div>
            </div>


            <div class="carousel-item">
              <div class="container-fluid">
                <div class="row">

                  <div class="col-1"></div>

                  <div class="col-10 text-center">
                    <img class="mt-5" src="assets/img/icons/iconstep3.png" style="width: 30%;">
                    <p class="bebas bigtitle textwhite" style="line-height: normal;">Nosotros te lo confeccionamos </p>
                    <p class="poppins regulartext textwhite">Hacemos de tu diseño una pieza real con telas sostenibles y
                      duraderas. </p>
                  </div>

                  <div class="col-1 "></div>
                </div>
              </div>
            </div>


            <div class="carousel-item">
              <div class="container-fluid">
                <div class="row">

                  <div class="col-1"></div>

                  <div class="col-10 text-center">
                    <img class="mt-5" src="assets/img/icons/iconstep4.png" style="width: 30%;">
                    <p class="bebas bigtitle textwhite" style="line-height: normal;">Disfrutá tu diseño exclusivo</p>
                    <p class="poppins regulartext textwhite" style="">Una vez listo el diseño, estará pronto en tus
                      manos. </p>
                  </div>

                  <div class="col-1 "></div>
                </div>
              </div>
            </div>

          </div>
          <a class="carousel-control-prev" href="#carouselExampleControlsicons" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true" style="filter: invert();"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControlsicons" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true" style="filter: invert();"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>

    </div>
  </div>
</section>

<section class="" style="background-color: #f9f9f9;">
  <div class="container">
    <div class="row">

      <div class="col-12 col-md-6">
        <img class="mt-5 mb-5" [src]="url+'portada/resources/thumbnails/' + portada?.portadaMujer" style="width: 90%;margin-left: 5%;">
      </div>

      <div class="col-12 col-md-6 text-center">
        <p class="hugetitle bebas textblack wforw" style="line-height: 1;">Nadie es como vos y ese es tu poder</p>
        <div class="container">
          <div class="row">
            <div class="col-1"></div>
            <div class="col-10">
              <p class="poppins regulartext textblack">La confianza de cada mujer nos inspira a crear prendas únicas, que
                reflejen su esencia e irradien su belleza interior.</p>
              <p class="modernline textblack womanfrase mt-3 mt-md-0 mb-5 mb-md-0">La fuerza es la nueva belleza</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>


<section class="">
  <div class="container pt-5 pb-5" >
    <h2 class="poppins hugetext text-center pb-4">Quizás te interese</h2>
    <div class="row">
      <div class="col-6 col-md-3 col-lg-3 text-center mb-5 mt-0 mt-md-3"
           *ngFor="let slide of news_productos; let i=index">
        <img  [src]="over[i] ? url+'productos_img_data/resources/thumbnails/'+slide.hoverPoster:  url+'productos_img_data/resources/thumbnails/'+slide.poster"
              [routerLink]="['/producto/',slide.slug]"
              (mouseover)="over[i] = true" (mouseout)="over[i] = false"  style="cursor: pointer;">
        <h4 class="poppinsbold regulartext textblack mt-2" style="margin-bottom: 1%"><a class="poppins regulartext textblack"
                                                                                   style="color: black;"
                                                                                   [routerLink]="['/producto/',slide.slug]">{{slide.titulo.substr(0, 68)}}</a>
        </h4>
        <p class="roboto bigtext textblack mt-2 mt-md-2 mt-lg-2 mt-xl-2 mb-2 mb-md-2 mb-lg-2 mb-xl-2"
           style="margin-bottom: 2%">$ {{slide.precio_ahora}}</p>
        <a [routerLink]="['/producto/',slide.slug]">
          <button type="button" class="btn btn-dark btn-sm" style="">Ver producto
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </button>
        </a>
      </div>
    </div>
  </div>
</section>


<section class="mb-5 mb-md-5">
  <div class="container none-mobile">
    <div class="row graycolor">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="container">
          <div class="row">

            <div class="col-12 col-md-4">
              <img src="assets/img/icons/iconsbox1.png" style="width: 12%;margin-bottom: 6%;margin-top: 5%;">
              <h5 class="poppinsbold regulartext textblack gray-box-titles" style="color: #606060;">Productos de calidad</h5>
              <p class="poppins smalltext textblack mb-4">Lucí diseños orgánicos, con sello de calidad y sostenibilidad.</p>
            </div>

            <div class="col-12 col-md-4">
              <img src="assets/img/icons/iconbox2.png" style="width: 12%;margin-bottom: 7%;margin-top: 5%;">
              <h5 class="poppinsbold regulartext textblack gray-box-titles" style="color: #606060;">Pagos seguros en línea
              </h5>
              <p class="poppins smalltext textblack mb-4">Podés pagar con tarjeta de bébito o crédito,
                SINPE MÓVIL o PayPal.</p>
            </div>

            <div class="col-12 col-md-4">
              <img src="assets/img/icons/iconbox3.png" style="width: 12%;margin-bottom: 7%;margin-top: 5%;">
              <h5 class="poppinsbold regulartext textblack gray-box-titles" style="color: #606060;">Envíos a todo el mundo</h5>
              <p class="poppins smalltext textblack mb-4">Seleccioná el país al cual deseás que se entregue tu pedido.</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container none-desktop">
    <div class="row">
      <div class="col-1"></div>
      <div class="col-10 graycolor">
        <div class="container">
          <div class="row">

            <div class="col-12">
              <img src="assets/img/icons/iconsbox1.png" style="width: 12%;margin-bottom: 6%;margin-top: 5%;">
              <p class="poppinsbold regulartext textblack" style="line-height: 0;color: #606060;">Productos de calidad</p>
              <p class="poppins smalltext textblack mb-4">Lucí diseños orgánicos, con sello de calidad y sostenibilidad.</p>
            </div>

            <div class="col-12">
              <img src="assets/img/icons/iconbox2.png" style="width: 12%;margin-bottom: 7%;margin-top: 5%;">
              <p class="poppinsbold regulartext textblack" style="line-height: 0;color: #606060;">Pagos seguros en línea</p>
              <p class="poppins smalltext textblack mb-4">Podés pagar con tarjeta de bébito o crédito,
                SINPE MOVIL o PayPal.</p>
            </div>

            <div class="col-12">
              <img src="assets/img/icons/iconbox3.png" style="width: 12%;margin-bottom: 7%;margin-top: 5%;">
              <p class="poppinsbold regulartext textblack" style="line-height: 0;color: #606060;">Envíos a todo el mundo</p>
              <p class="poppins smalltext textblack mb-4">Seleccioná el país al cual deseás que se entregue tu pedido.</p>
            </div>

          </div>
        </div>
      </div>
      <div class="col-1"></div>
    </div>
  </div>


</section>




<app-foot></app-foot>
