import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from "./GLOBAL";

@Injectable({
  providedIn: 'root'
})
export class PreguntaService {
  public url;
  constructor(
    private _http : HttpClient,
  ) {
    this.url = GLOBAL.url;
  }
  listar():Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.get(this.url + 'pregunta',{headers:headers})
  }

  eliminar(id):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.delete(this.url + 'pregunta/'+id,{headers:headers})
  }

  registro(data):Observable<any>{
    var fd = new FormData();
    fd.append('pregunta',data.pregunta);
    fd.append('respuesta',data.respuesta);
    return this._http.post(this.url + 'pregunta/registro',fd);
  }
}
