import { Component, OnInit } from '@angular/core';
import { ArticleService } from "../../services/article.service";
import {GLOBAL} from "../../services/GLOBAL";
import {CategoriaService} from '../../services/categoria.service';



@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {
  public articles;
  public url;

  public isMobile = true;

  constructor(private _articleService: ArticleService) {
    this.url = GLOBAL.url;
  }

  ngOnInit(): void {
    this.data_articles();

    if (window.screen.width > 600) {
      this.isMobile = false;
    }
  }

  data_articles() {

    this._articleService.listar('').subscribe(
      response => {
        this.articles = response.articles;
      },
      error => {
        var t = 0;
      }
    );
  }

}
