<app-nav></app-nav>

<section>
  <div class="container-fluid remove-all-margin-padding">
    <div class="row remove-all-margin-padding">

      <div class="col-12 remove-all-margin-padding none-mobile">
        <img  [src]="url+'article/resources/thumbnails/'+article?.banner" style="width: 100%;">
      </div>

      <div class="col-12 remove-all-margin-padding none-desktop">
        <img [src]="url+'article/resources/thumbnails/'+article?.mobile_banner"style="width: 100%;">
      </div>

    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-8 col-xl-8">
        <h1 class="poppinsbold show-articletitle textblack mt-4 mt-md-3 mt-lg-5 mt-xl-5 center-text-component">{{article?.nombre}}</h1>
        <div id="detalle" class="pb-4">
          {{article?.body}}
        </div>
      </div>

      <div class="col-12 col-md-12 col-lg-4 col-xl-4 mt-5">

        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-6 col-lg-12 col-xl-12" *ngFor="let article of randomArticles">
              <div class="card text-white mb-4" style="border-radius: 0 !important;">
                <img [src]="url+'article/resources/thumbnails/'+article.mobile_banner" class="card-img" class="card-img" alt="..." style="border-radius: 0 !important; background-color: #b1a0a2">
                <div class="card-img-overlay text-center">
                  <h5 class="card-title poppinsbold bigtext textwhite text-center mt-5">{{article.nombre}}</h5>
                  <a [routerLink]="['/articulo/'+article.slug]">
                    <button class="btn btn-light btn-sm" style="border-radius: 0 !important;">Leer Artículo
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>


</section>

<app-foot></app-foot>
