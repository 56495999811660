import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ProductoService} from 'src/app/services/producto.service';
import {PortadaService} from 'src/app/services/portada.service';
import {GLOBAL} from 'src/app/services/GLOBAL';
import {GaleriaService} from 'src/app/services/galeria.service';
import {ColorService} from 'src/app/services/color.service';
import {SelectorService} from 'src/app/services/selector.service';
import {ComentarioService} from 'src/app/services/comentario.service';
import {UserService} from 'src/app/services/user.service';
import {CarritoService} from 'src/app/services/carrito.service';

declare let tns;
// import * as io from "socket.io-client";
import {MarcaService} from 'src/app/services/marca.service';
import {CategoriaService} from 'src/app/services/categoria.service';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';

declare let countdown;


declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit, AfterViewInit {
  @ViewChild('materials')
  materialsSection: ElementRef;

  public identity;
  public best_sellers: Array<any> = [];
  public url;
  public selectores: Array<any> = [];
  public galeria: Array<any> = [];
  public colores: Array<any> = [];
  public first_img;
  public select_producto;
  // public socket = io('http://34.68.74.165:4201');

  public cinco_estrellas = 0;
  public cuatro_estrellas = 0;
  public tres_estrellas = 0;
  public dos_estrellas = 0;
  public una_estrella = 0;

  public cinco_porcent = 0;
  public cuatro_porcent = 0;
  public tres_porcent = 0;
  public dos_porcent = 0;
  public uno_porcent = 0;
  public raiting_porcent = 0;
  public total_puntos = 0;
  public max_puntos = 0;
  public raiting_puntos = 0;
  public comentarios: Array<any> = [];

  public color_to_cart;
  public cantidad_to_cart = 1;
  public precio_to_cart;
  public selector_to_cart = ' ';
  public err_stock = '';
  public selector_error = false;
  public marcas: Array<any> = [];

  public promocion: any = {};
  public categorias;
  public categoriaLatest;
  public productoLatest;

  public productos_best_sellers: Array<any> = [];
  public productos_newest: Array<any> = [];
  public productos_populares: Array<any> = [];

  public last_reviews: Array<any> = [];
  public sliders: Array<any> = [];
  public headers = false;

  public news_productos: [];
  public portada: any = {};
  private materials = false;
  public over= [];
  public extensionLists = {
    video: ['m4v', 'avi', 'mpg', 'mp4', 'webm'],
    image: ['jpg', 'gif', 'bmp', 'png']
  };

  public desktopFileType = 'image';
  public mobileFileType = 'image';

  public isMobile = true;

  constructor(
    private _route: ActivatedRoute,
    private _productoService: ProductoService,
    private _portadaService: PortadaService,
    private _galeriaService: GaleriaService,
    private _colorService: ColorService,
    private _selectorService: SelectorService,
    private _comentarioService: ComentarioService,
    private _userSerice: UserService,
    private _carritoService: CarritoService,
    private _marcaService: MarcaService,
    private _categoriaService: CategoriaService,
    private _router: Router,
  ) {
    this.url = GLOBAL.url;
    this.identity = this._userSerice.getIdentity();
  }

  ngOnInit(): void {

    if (window.screen.width > 600) {
      this.isMobile = false;
    }

    this.listar_newest();
    this._productoService.best_seller().subscribe(
      response => {
        this.best_sellers = response.data;
      }
    );

    this.get_marcas();
    this.data_categorias();
    this.listar_latest();
    this.get_portada();

    this._productoService.best_seller().subscribe(
      response => {
        this.productos_best_sellers = response.data;
      }
    );


    this._productoService.populares().subscribe(
      response => {
        this.productos_populares = response.data;
      },
      error => {

      }
    );

    this._comentarioService.listar_last().subscribe(
      response => {
        this.last_reviews = response.data;
      }
    );
  }

  ngAfterViewInit() {
    setInterval(function(){
      let elem = document.getElementById('nextSlideDesktop')  ;
      let elemMobile = document.getElementById('nextSlideMobile') ;

      let evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      });
      if (elem && elemMobile) {
        elem.dispatchEvent(evt);
      }
      if (elemMobile) {
        elemMobile.dispatchEvent(evt);
      }
      }, 4000);

    this._route.params.subscribe(
      params => {
        if (params.subplace && params.subplace == 'materiales') {
          this.materialsSection.nativeElement.scrollIntoView();
        }
      }
    );
  }


  data_countdown(fecha) {
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;


    let countDown = new Date(fecha).getTime(),
      x = setInterval(function() {

        let now = new Date().getTime(),
          distance = countDown - now;

        $('#count_dias').text(Math.floor(distance / (day))),
          $('#count_horas').text(Math.floor((distance % (day)) / (hour))),
          $('#count_min').text(Math.floor((distance % (hour)) / (minute))),
          $('#count_seg').text(Math.floor((distance % (minute)) / second));


      }, second);
  }

  get_color(event, color) {
    this.color_to_cart = color.color;

  }

  click_img(img, id) {

    $('.cz-thumblist-item.active').removeClass('active');
    $('#' + id).addClass('active');
    this.first_img = img;

  }


  add_to_cart(carritoForm) {
    /*    if(this.cantidad_to_cart > this.select_producto.stock){
          this.err_stock = 'La cantidad no debe superar al stock';
        }
        else if(this.cantidad_to_cart <= 0){
          this.err_stock = 'La cantidad no puede ser un valor negativo';
        }
        else{
          this.err_stock = '';*/
    let data = {
      // user: this.identity._id,
      producto: this.select_producto,
      cantidad: this.cantidad_to_cart,
      color: this.color_to_cart,
      selector: this.selector_to_cart,
      precio: this.select_producto.precio_ahora
    };
    if (this.selector_to_cart != ' ') {
      this.selector_error = false;
      this._carritoService.registro(data).subscribe(
        response => {
          // this.socket.emit('save-carrito', {new:true});

        },
        error => {


        }
      );
    } else {
      this.selector_error = true;
    }
    // }

  }

  get_marcas() {
    this._marcaService.listar('').subscribe(
      response => {
        this.marcas = response.marcas;

      },
      error => {

      }
    );
  }

  get_galeria(item) {
    this.galeria = [];
    this.select_producto = item;


    this._galeriaService.find_by_product(item._id).subscribe(
      response => {
        this.data_comentarios(item._id);
        this.data_colores(item._id);
        this.data_selectores(item._id);
        this.err_stock = '';
        this.selector_error = false;
        this.color_to_cart;
        this.cantidad_to_cart = 1;
        this.precio_to_cart;
        this.selector_to_cart = ' ';
        response.galeria.forEach((element, index) => {
          if (index == 0) {
            this.first_img = element.imagen;
          }
          this.galeria.push({_id: element._id, imagen: element.imagen});
        });
      },
      error => {


      }
    );
  }

  data_colores(id) {
    this._colorService.listar(id).subscribe(
      response => {
        this.colores = response.colores;
        this.color_to_cart = this.colores[0].color;


      },
      error => {

      }
    );
  }

  data_selectores(id) {
    this._selectorService.listar(id).subscribe(
      response => {
        this.selectores = response.selectores;

      },
      error => {

      }
    );
  }

  data_comentarios(id) {
    this._comentarioService.get_data(id, 'raiting').subscribe(
      response => {

        this.comentarios = response.comentarios;


        this.comentarios.forEach((element, index) => {
          if (element.estrellas == 5) {
            this.cinco_estrellas = this.cinco_estrellas + 1;
          } else if (element.estrellas == 4) {
            this.cuatro_estrellas = this.cuatro_estrellas + 1;
          } else if (element.estrellas == 3) {
            this.tres_estrellas = this.tres_estrellas + 1;
          } else if (element.estrellas == 2) {
            this.dos_estrellas = this.dos_estrellas + 1;
          } else if (element.estrellas == 3) {
            this.una_estrella = this.una_estrella + 1;
          }
        });

        this.cinco_porcent = (this.cinco_estrellas * 100) / this.comentarios.length;
        this.cuatro_porcent = (this.cuatro_estrellas * 100) / this.comentarios.length;
        this.tres_porcent = (this.tres_estrellas * 100) / this.comentarios.length;
        this.dos_porcent = (this.dos_estrellas * 100) / this.comentarios.length;
        this.uno_porcent = (this.una_estrella * 100) / this.comentarios.length;

        /******************************************************************* */

        let puntos_cinco = 0;
        let puntos_cuatro = 0;
        let puntos_tres = 0;
        let puntos_dos = 0;
        let punto_uno = 0;

        puntos_cinco = this.cinco_estrellas * 5;
        puntos_cuatro = this.cuatro_estrellas * 4;
        puntos_tres = this.tres_estrellas * 3;
        puntos_dos = this.dos_estrellas * 2;
        punto_uno = this.una_estrella * 1;

        this.total_puntos = puntos_cinco + puntos_cuatro + puntos_tres + puntos_dos + punto_uno;
        this.max_puntos = this.comentarios.length * 5;

        this.raiting_porcent = (this.total_puntos * 100) / this.max_puntos;

        this.raiting_puntos = (this.raiting_porcent * 5) / 100;

        if (isNaN(this.raiting_puntos)) {
          this.raiting_puntos = 0;
        }
        if (isNaN(this.raiting_porcent)) {
          this.raiting_porcent = 0;
        }

      },
      error => {
        console.log(error);

      }
    );
  }

  data_categorias() {
    this._categoriaService.get_car_slide().subscribe(
      response => {
        this.categorias = response.categorias;
      },
      error => {

      }
    );
  }

  listar_latest() {
    this._categoriaService.listarLatest().subscribe(
      response => {
        this.categoriaLatest = response.categoria;
        this._productoService.listar_cat(this.categoriaLatest._id).subscribe(
          response => {
            this.productoLatest = response.productos;
            this.over = new Array(this.productoLatest.length);
            this.over.fill(false);
           },

          error => {

          }
      );
      },
      error => {

      }
    );
  }

  listar_newest() {
    this._productoService.listar_newest().subscribe(
      response => {
        this.news_productos = response.data;
        this.over = new Array(this.news_productos.length);
        this.over.fill(false);
      },
      error => {

      }
    );
  }

  get_portada( ) {
    this._portadaService.get_data().subscribe(
      response => {
        this.portada = response.imagenes;
        if (this.portada.tipo == 'Portada') {
          if (this.extensionLists.video.includes(this.portada.portada.split('.').pop())) {
            this.desktopFileType = 'video';
          } else {
            this.desktopFileType = 'image';
          }
          if (this.extensionLists.video.includes(this.portada.portadaMobile.split('.').pop())) {
            this.mobileFileType = 'video';
          } else {
            this.mobileFileType = 'image';
          }
        }
      },
      error => {

      }
    );
  }



}
