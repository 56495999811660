
<div class="container py-4 py-lg-5 my-4">
    <div class="row">
      <div class="col-1 col-md-3 "></div>
      <div class="col-10 col-md-6">
        <div class="card border-0 box-shadow">
          <div class="card-body">
            <h2 class="h4 mb-1">Iniciar Sesión</h2>

            <hr>
            <h3 class="font-size-base pt-4 pb-2">Utiliza tus credenciales para iniciar sesión</h3>

            <div *ngIf="data_error" class="alert alert-danger alert-dismissible fade show" role="alert">
              {{data_error}}
              <button class="close" (click)="close_alert()" type="button" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <form class="needs-validation" #loginForm="ngForm" (ngSubmit)="login(loginForm)">
              <div class="input-group-overlay form-group">
                <div class="input-group-prepend-overlay">
                  <span class="input-group-text">
                      <i class="czi-mail"></i>
                  </span>
                </div>
                <input class="form-control prepended-form-control" type="email" placeholder="Correo electrónico" required autocomplete="off" #email="ngModel" name="email" [(ngModel)]="user.email">
              </div>
              <div class="input-group-overlay form-group">
                <div class="input-group-prepend-overlay"><span class="input-group-text"><i class="czi-locked"></i></span></div>
                <div class="password-toggle">
                  <input class="form-control prepended-form-control" id="password" type="password" placeholder="Password" required="" #password="ngModel" name="password" [(ngModel)]="user.password">
                  <label class="password-toggle-btn">
                    <input class="custom-control-input" (click)="view_password();" type="button"><i class="czi-eye password-toggle-indicator"></i><span class="sr-only">Ver contraseña</span>

                  </label>
                </div>
              </div>

              <hr class="mt-4">
              <div class="text-right pt-4">
                <button class="btn btn-dark" type="submit"><i class="czi-sign-in mr-2 ml-n21"></i>Ingresar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-1 col-md-3"></div>
    </div>
  </div>


