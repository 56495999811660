<app-aside></app-aside>

<div class="page-title-overlap bg-accent pt-4">
  <app-jumbo></app-jumbo>
</div>

<div class="container mb-5 pb-3">
  <div class="bg-light box-shadow-lg rounded-lg overflow-hidden">
    <div class="row">
      <!-- Sidebar-->
      <aside class="col-lg-4">
        <app-menu></app-menu>
      </aside>
      <!-- Content-->
      <section class="col-lg-8 pt-lg-4 pb-4 mb-3">
        <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
          <div class="row">
          
      
              <div class="col-lg-12">
                  <div class="border-bottom mt-lg-2 pb-2 mb-5">
                      <h1 class="mt-lg-4">Banner promocional</h1>
                      <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                        <p class="text-muted">Datos del banner de la página de inicio.</p>
                       
                      </div>
                      
                  </div>
              </div>
              <div class="col-lg-12 form-group" *ngIf="msm_success">
                <div class="alert alert-success alert-dismissible fade show" role="alert">
                    Se actualizó los datos de las configuraciones correctamente.
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
              </div>
              <div class="col-lg-12 form-group" *ngIf="msm_error">
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    Ocurrió un error al guardar las configuraciones.
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
              </div>
              <div class="col-lg-12">
                <form #promocionForm="ngForm" (ngSubmit)="onSubmit(promocionForm)">
                  <div class="card border-0 box-shadow">
                 
                      <div class="card-body" >
                          <div class="tab-content" style="margin-top: 20px;">
                              <div class="tab-pane fade active show">
                                  <div class="form-group row align-items-center">
                                      <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Titulo</label>
                                      <div class="col-md-9">
                                          <input class="form-control" required type="text" placeholder="Nombre del producto" #producto_title="ngModel" name="producto_title" [(ngModel)]="promocion.producto_title">
                                      </div>
                                  </div>
                                  <div class="form-group row align-items-center">
                                      <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Pre titulo</label>
                                      <div class="col-md-9">
                                          <input class="form-control" required type="text" placeholder="Titulo superior" #first_title="ngModel" name="first_title" [(ngModel)]="promocion.first_title">
                                      </div>
                                  </div>
                                  <div class="form-group row align-items-center">
                                      <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Etiqueta</label>
                                      <div class="col-md-9">
                                          <input class="form-control" required type="text" placeholder="Etiqueta del banner" #etiqueta="ngModel" name="etiqueta" [(ngModel)]="promocion.etiqueta">
                                      </div>
                                  </div>
                                  <div class="form-group row align-items-center">
                                      <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Subtitulo</label>
                                      <div class="col-md-9">
                                          <input class="form-control" required type="text" placeholder="Subtitulo del banner" #subtitulo="ngModel" name="subtitulo" [(ngModel)]="promocion.subtitulo">
                                      </div>
                                  </div>
                                  <div class="form-group row align-items-center">
                                      <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Fecha de termino</label>
                                      <div class="col-md-9">
                                          <input class="form-control" required type="text" placeholder="2020/12/25" #end="ngModel" name="end" [(ngModel)]="promocion.end">
                                      </div>
                                  </div>
                                  <div class="form-group row align-items-center">
                                    <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Slug del producto</label>
                                    <div class="col-md-9">
                                        <input class="form-control" required type="text" placeholder="Slug del producto promocional" #enlace="ngModel" name="enlace" [(ngModel)]="promocion.enlace">
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Estado</label>
                                    <div class="col-md-9">
                                      <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" name="estado" [(ngModel)]="promocion.estado" type="checkbox" id="ex-check-1">
                                        <label class="custom-control-label" *ngIf="promocion.estado" for="ex-check-1">Activo</label>
                                        <label class="custom-control-label" *ngIf="!promocion.estado" for="ex-check-1">Desactivado</label>
                                      </div>
                                    </div>
                                </div>
                                  <div class="form-group row align-items-center">
                                      <label class="col-md-3 col-form-label font-weight-medium" for="text-input">Imagen</label>
                                      <div class="col-md-9">
                                          <!-- Drag and drop file upload -->
                                          <div class="cz-file-drop-area">
                                              <div class="cz-file-drop-preview img-thumbnail rounded">
                                                  <img [src]="imgSelect ||url+'congeneral/resources/thumbnails/'+promocion.banner" alt="04.jpg">
                                              </div>
                                              <span class="cz-file-drop-message">Suelta la imagen aquí</span>
                                              <input type="file" class="cz-file-drop-input" (change)="imgSelected($event)">
                                              
                                          </div>
                                      </div>
                                  </div>
                                  
                              </div>
                          </div>   
                      </div>
                      <div class="card-footer">
                          <button class="btn btn-primary mb-2 mr-1" type="submit">Actualizar campos</button>
                      </div>
                  </div>
              </form>
              </div>
          </div>
         
        </div>
      </section>
    </div>
  </div>
</div>
<app-foot></app-foot>