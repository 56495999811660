<app-aside></app-aside>

<div class="page-title-overlap pt-4" style="background-color: #262626;">
  <app-jumbo></app-jumbo>
</div>

<div class="container mb-5 pb-3">
  <div class="bg-light box-shadow-lg rounded-lg overflow-hidden">
    <div class="row">
      <!-- Sidebar-->
      <aside class="col-lg-4">
        <app-menu></app-menu>
      </aside>
      <!-- Content-->
      <section class="col-lg-8 pt-lg-4 pb-4 mb-3">
        <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
          <div class="row">
            <div class="col-lg-12">
              <div class="border-bottom mt-lg-2 pb-2 mb-5">
                <h1 class="mt-lg-4">Redacción de entrada</h1>
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                  <p class="text-muted">Todos los campos son obligatorios</p>
                  <p class="font-size-sm pl-md-4"><a class="text-nowrap" [routerLink]="['/admin/articles']" >Regresar<i class="czi-arrow-right align-middle ml-1"></i></a></p>
                </div>
              </div>

              <div *ngIf="msm_error" (click)="close_alert()" class="alert alert-danger alert-dismissible fade show" role="alert">
                {{msm_error}}
                <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
              </div>

              <div *ngIf="msm_success" (click)="close_alert()" class="alert alert-success alert-dismissible fade show" role="alert">
                {{msm_success}}
                <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
              </div>
              <form #categoriaForm="ngForm" (ngSubmit)="onSubmit(categoriaForm)">
                <div class="card border-0 box-shadow">

                  <div class="card-body" >
                    <div class="tab-content" style="margin-top: 20px;">
                      <div class="tab-pane fade active show">
                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Titulo</label>
                          <div class="col-md-10">
                            <input class="form-control" type="text" maxlength="100" placeholder="Titulo del artículo" #nombre="ngModel" name="nombre" [(ngModel)]="article.nombre">
                          </div>
                        </div>
                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Banner</label>
                          <div class="col-md-10">
                            <!-- Drag and drop file upload -->
                            <div class="cz-file-drop-area">
                              <div *ngIf="!article['banner']" class="cz-file-drop-icon czi-cloud-upload"></div>
                              <div *ngIf="article['banner']" class="cz-file-drop-preview img-thumbnail rounded">
                                <img src="{{imgSelect.banner}}">
                              </div>
                              <span class="cz-file-drop-message">Suelta la imagen aquí</span>
                              <input type="file" class="cz-file-drop-input" (change)="imgSelected($event, 'banner')">

                            </div>
                            <p class="small text-muted">Tamaño requerido: 1200 x 350 px</p>
                          </div>
                        </div>

                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Mobile Banner</label>
                          <div class="col-md-10">
                            <!-- Drag and drop file upload -->
                            <div class="cz-file-drop-area">
                              <div *ngIf="!article['mobile_banner']" class="cz-file-drop-icon czi-cloud-upload"></div>
                              <div *ngIf="article['mobile_banner']" class="cz-file-drop-preview img-thumbnail rounded">
                                <img src="{{imgSelect.mobile_banner}}">
                              </div>
                              <span class="cz-file-drop-message">Suelta la imagen aquí</span>
                              <input type="file" class="cz-file-drop-input" (change)="imgSelected($event, 'mobile_banner')">

                            </div>
                            <p class="small text-muted">Tamaño requerido: 850 x 520 px</p>
                          </div>
                        </div>
                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium" for="textarea-input">Cuerpo</label>
                          <div class="col-md-10">
                            <tinymce #body="ngModel" [(ngModel)]="article.body" required name="body" [config]="config"></tinymce>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="msm_error" (click)="close_alert()" class="alert alert-danger alert-dismissible fade show" role="alert">
                    {{msm_error}}
                    <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-primary mb-2 mr-1" type="submit">Publicar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
