<footer class="darkgraycolor pt-5">

  <section class="mb-5">


    <div class="container none-mobile">
      <div class="row">

        <div class="col-md-3 col-lg-3 col-xl-4">
          <a [routerLink]="['/home/inicio']"><img class="logo-footer-desktop mt-md-3 mt-xl-0" src="assets/img/whitelogo.png"
                                           style=""></a>
        </div>


        <div class="col-md-3  col-xl-3">
          <h1 class="poppinsbold textwhite bigtext mt-3 ml-1" style="letter-spacing: 2px;"><strong>Explorar</strong></h1>
          <ul class="ml-1" style="text-decoration: none;list-style-type: none;padding: 0px;">
            <li class="poppins smalltext textwhite mt-2" style="cursor:pointer;"><a style="color: white"
                                                                                 [routerLink]="['/productos/'+ categoriaLatest?.nombre?.toLowerCase().trim()]">Nuevos
              diseños</a></li>
            <li class="poppins smalltext textwhite mt-2" style="cursor:pointer;"><a style="color: white"
                                                                                 [routerLink]="['/colecciones']">Todas
              las colecciones</a></li>
            <li class="poppins smalltext textwhite mt-2" style="cursor:pointer;"><a style="color: white"
                                                                                 [routerLink]="['/sobrenosotros']">Sobre
              la diseñadora</a></li>
            <li class="poppins smalltext textwhite mt-2" style="cursor:pointer;"><a style="color: white"
                                                                                 href="#textil-section">Nuestros
              textiles</a></li>
            <li class="poppins smalltext textwhite mt-2" style="cursor:pointer;"><a style="color: white"
                                                                                 [routerLink]="['/articulos']">Artículos
              de Nolys</a></li>
            <li class="poppins smalltext textwhite mt-2" style="cursor:pointer;"><a style="color: white"
                                                                                 [routerLink]="['/preguntasfrecuentes']">Preguntas
              Frecuentes</a></li>
            <li class="poppins smalltext textwhite mt-2" style="cursor:pointer;"><a style="color: white"
                                                                                 [routerLink]="['/contacto']">Contactanos</a></li>
          </ul>
        </div>

        <div class="col-md-3 col-lg-3 col-xl-3">
          <h2 class="poppins textwhite regulartext mt-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                 class="bi bi-telephone-fill mr-1" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
            </svg>
            Teléfono
          </h2>
<!--          <p class="smalltext textwhite poppins" style="line-height: 1;"><strong>Tel:</strong> +506 7260 1197</p>-->
          <p class="smalltext textwhite poppins" style="line-height: 0.5;"><strong>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                 class="bi bi-whatsapp  mr-1" viewBox="0 0 16 16" style="color: white;">
              <path
                d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
            </svg>
          </strong> +506 7260 1197
          </p>

          <h3 class="poppins textwhite regulartext mt-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                 class="bi bi-envelope-fill" viewBox="0 0 16 16">
              <path
                d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
            </svg>
            E-mail
          </h3>
          <p class="smalltext textwhite poppins" style="line-height: 1;"><strong>info@amagnr.com</strong></p>

        </div>

        <div class="col-md-3 col-lg-3 col-xl-2">

          <h4 class="poppins textwhite bigtext mt-3" style="letter-spacing: 2px;cursor: pointer;"><a target="_blank"
                                                                                                  href="https://www.instagram.com/amagbynr/"
                                                                                                  style="color: white;">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-instagram mr-1"
                 viewBox="0 0 16 16">
              <path
                d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
            </svg>
            amagbynr</a>
          </h4>

          <h5 class="poppins textwhite bigtext mt-3" style="letter-spacing: 2px;cursor: pointer;"><a target="_blank"
                                                                                                  href="https://www.facebook.com/amagbynr/"
                                                                                                  style="color: white;">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                 class="bi bi-facebook mr-1" viewBox="0 0 16 16">
              <path
                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
            </svg>
            amagbynr</a>
          </h5>

        </div>


      </div>
    </div>


    <div class="container-fluid none-desktop">
      <div class="row">
        <div class="col-12 text-center">
          <a [routerLink]="['/home/inicio']"><img class="mt-5 mb-5" src="assets/img/whitelogo.png" style="width: 60%;"></a>
        </div>

        <div class="col-12 text-center mt-3">
          <a target="_blank" href="https://www.instagram.com/amagbynr/" style="color: white;">
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor"
                 class="bi bi-instagram mr-5" viewBox="0 0 16 16" style="color: white;">
              <path
                d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
            </svg>
          </a>

          <a target="_blank" href="https://www.facebook.com/amagbynr/" style="color: white;">
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor"
                 class="bi bi-facebook  mr-5" viewBox="0 0 16 16" style="color: white;">
              <path
                d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
            </svg>
          </a>

          <a href="https://api.whatsapp.com/send?phone=50672601197."  target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor"
                 class="bi bi-whatsapp  mr-5" viewBox="0 0 16 16" style="color: white;">
              <path
                d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
            </svg>
          </a>

          <a href="mailto:amag@amagnr.com"  target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor"
                 class="bi bi-envelope-fill" viewBox="0 0 16 16" style="color: white;">
              <path
                d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
            </svg>
          </a>
        </div>

        <div class="col-12 text-center mt-5 mb-3">
          <p class="poppins regulartext textwhite"><strong>E-mail:</strong> info@amagnr.com</p>
        </div>

        <div class="col-12 mb-4"><span class="decor-mobile-footer"></span></div>

        <div class="col-12 mt-4 text-center">
          <h3 class="poppinsbold hugetext textwhite"><strong>Explorar</strong></h3>
          <ul style="list-style-type: none; padding: 0%;">
            <li class="poppins bigtext textwhite mt-3 mb-3"><a
              [routerLink]="['/productos/'+ categoriaLatest?.nombre?.toLowerCase().trim()]" style="color: white">Nuevos
              diseños</a></li>
            <li class="poppins bigtext textwhite mt-3 mb-3"><a [routerLink]="['/colecciones']" style="color: white">Todas
              las colecciones</a></li>
            <li class="poppins bigtext textwhite mt-3 mb-3"><a [routerLink]="['/sobrenosotros']" style="color: white">Sobre
              la diseñadora</a></li>
            <li class="poppins bigtext textwhite mt-3 mb-3"><a href="#textil-section" style="color: white">Nuestros
              textiles</a></li>
            <li class="poppins bigtext textwhite mt-3 mb-3"><a [routerLink]="['/articulos']" style="color: white">Artículos
              de Nolys</a></li>
            <li class="poppins bigtext textwhite mt-3 mb-3"><a [routerLink]="['/preguntasfrecuentes']"
                                                            style="color: white">Preguntas Frecuentes</a></li>
            <li class="poppins bigtext textwhite mt-3 mb-3"><a [routerLink]="['/contacto']" style="color: white">Contactanos</a></li>
          </ul>
        </div>
      </div>
    </div>


  </section>


  <section>
    <div class="container none-mobile">
      <div class="row">
        <div class="col-12">
          <p class="roboto bigtext textwhite text-center mt-md-3 mt-xl-5  developed"><a class="textwhite bigtext"
                                                                                        target="_blank"
                                                                                        href="https://linkdesign.cr"
                                                                                        style="color: white;">Developed
            by
            <strong>LinkDesign</strong></a></p>
        </div>
      </div>
    </div>

    <div class="container none-desktop">
      <div class="row">
        <div class="col-12">
          <p class="poppins smalltext textwhite text-center mt-4" style="margin-bottom: 42%;"><a class="textwhite bigtext"
                                                                                              target="_blank"
                                                                                              href="https://linkdesign.cr"
                                                                                              style="color: white;">Developed
            by
            <strong>LinkDesign</strong></a></p>
        </div>
      </div>
    </div>
  </section>

</footer>


<!-- Toolbar for handheld devices-->
<div class="cz-handheld-toolbar">
  <div class="d-table table-fixed w-100">

    <a class="d-table-cell cz-handheld-toolbar-item"
       [routerLink]="['/productos/'+ categoriaLatest?.nombre?.toLowerCase().trim()]">
      <span class="cz-handheld-toolbar-icon"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                                  fill="currentColor" class="bi bi-stars textblack" viewBox="0 0 16 16">
  <path
    d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828l.645-1.937zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.734 1.734 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.734 1.734 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.734 1.734 0 0 0 3.407 2.31l.387-1.162zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L10.863.1z"/>
</svg>
      </span><span class="cz-handheld-toolbar-label poppins smalltext textblack">Lo nuevo</span></a>


    <a class="d-table-cell cz-handheld-toolbar-item" [routerLink]="['/colecciones']">
      <span class="cz-handheld-toolbar-icon"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                  fill="currentColor"
                                                  class="bi bi-collection-fill textblack"
                                                  viewBox="0 0 16 16">
  <path
    d="M0 13a1.5 1.5 0 0 0 1.5 1.5h13A1.5 1.5 0 0 0 16 13V6a1.5 1.5 0 0 0-1.5-1.5h-13A1.5 1.5 0 0 0 0 6v7zM2 3a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11A.5.5 0 0 0 2 3zm2-2a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7A.5.5 0 0 0 4 1z"/>
</svg></span><span
      class="cz-handheld-toolbar-label poppins smalltext textblack">Colecciones</span></a>


    <a class="d-table-cell cz-handheld-toolbar-item" [routerLink]="['/cuenta/shop-cart']"><span
      class="cz-handheld-toolbar-icon"><i class="czi-bag textblack"></i><span
      class="badge badge-primary badge-pill ml-1">{{carrito.length}}</span></span><span
      class="cz-handheld-toolbar-label poppins smalltext textblack">My Bag</span></a>
  </div>
</div>

<!-- Back To Top Button--><a class="btn-scroll-top" href="#top" data-scroll><span
  class="btn-scroll-top-tooltip text-muted font-size-sm mr-2">Top</span><i
  class="btn-scroll-top-icon czi-arrow-up"> </i></a>
