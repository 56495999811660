export class Categoria{
    constructor(
        public _id: string,
        public nombre: string,
        public description: string,
        public subcategorias: string,
        public banner: string,
        public mobile_banner: string,
        public organicPercentage: string,
        public recyclingPercentage: string

    ){
    }
}
