import { Component, OnInit } from '@angular/core';
import {CategoriaService} from "../../services/categoria.service";
import {HitosService} from '../../services/hito.service';
import {milestones} from '../../models/Hito';
import {GLOBAL} from "../../services/GLOBAL";


@Component({
  selector: 'app-aboutdesigner',
  templateUrl: './aboutdesigner.component.html',
  styleUrls: ['./aboutdesigner.component.css']
})
export class AboutdesignerComponent implements OnInit {

  public categorias;
  public url;
  public hitos: Array<any> = [];
  public milestones = new milestones('', '', '');

  constructor(
    private _categoriaService: CategoriaService,
    private _hitoService: HitosService
  ) {
    this.url = GLOBAL.url;
  }

  ngOnInit(): void {
    this.data_categorias();
    this.initData()
  }

  initData() {
    this._hitoService.listar().subscribe(
      response => {
        this.hitos = response.milestones;
      },
      error => {

      }
    );
  }


  data_categorias() {

    this._categoriaService.listar('').subscribe(
      response => {
        this.categorias = response.categorias;
      },
      error => {

      }
    );
  }

}
