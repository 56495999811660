import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {CarritoService} from 'src/app/services/carrito.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductoService} from 'src/app/services/producto.service';
import {GLOBAL} from 'src/app/services/GLOBAL';
// import * as io from "socket.io-client";
import {CuponService} from 'src/app/services/cupon.service';
import {PostalService} from 'src/app/services/postal.service';
import {DireccionService} from 'src/app/services/direccion.service';
import {VentaService} from 'src/app/services/venta.service';

declare var paypal;


declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.css']
})
export class CarritoComponent implements OnInit {

  @ViewChild('paypal', {static: true}) paypalElement: ElementRef;

  public carrito: Array<any> = [];
  public direcciones;
  public identity;
  public subtotal = 0;
  public url;
  // public socket = io('http://localhost:4201');
  public cupon;
  public msm_error_cupon = false;
  public msm_success_cupon = false;
  public new_data_descuento;
  public data_keyup = 0;
  public data_save_carrito;
  public msm_error = '';
  public productos: any = {};

  public news_productos: any = [];

  public paypal;

  public postales;


  //DATA
  public radio_postal;
  public medio_postal: any = {};
  public data_cupon;
  public id_direccion = '';
  public data_direccion: any = {};
  // public data_detalle : Array<any> = [];
  public data_venta: any = {};
  public error_stock = false;
  public show_alternatice_paymente = true;
  public date_string;
  public over= [];

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _productoService: ProductoService,
    private _carritoService: CarritoService,
    private _cuponService: CuponService,
    private _postalService: PostalService,
    private _direccionService: DireccionService,
    private _ventaService: VentaService,
  ) {

  }

  ngOnInit(): void {

    this.listar_newest();

    /*    if(this.identity){
          this.socket.on('new-stock', function (data) {
            this.listar_carrito();

          }.bind(this));*/

    $('#card-pay').hide();
    $('#btn-back-data').hide();
    $('#card-data-envio').hide();
    this.listar_carrito();
    paypal.Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [{
            description: 'Compra en Linea',
            amount: {
              currency_code: 'USD',
              value: Math.round(this.subtotal),
            }
          }]
        });
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        this.data_venta.idtransaccion = order.purchase_units[0].payments.captures[0].id;
        this.data_venta.direccion = order.purchase_units[0].shipping.address.address_line_1;
        this.data_venta.destinatario = order.purchase_units[0].shipping.name.full_name;
        this.data_venta.referencia = order.purchase_units[0].shipping.address.address_line_2;
        this.data_venta.pais = order.purchase_units[0].shipping.address.country_code;
        this.data_venta.ciudad = order.purchase_units[0].shipping.address.admin_area_2;
        this.data_venta.zip = order.purchase_units[0].shipping.address.postal_code;
        this.data_venta.provincia = order.purchase_units[0].shipping.address.admin_area_1;
        this.data_venta.email = order.payer.email_address;
        this.data_venta.mobile = order.payer.phone.phone_number.national_number;

        this._ventaService.registro(this.data_venta).subscribe(
          response => {
            this.remove_carrito();
            this.listar_carrito();
            this._router.navigate(['cuenta/ordenes/detalles/' + response.venta._id]);
          },
          error => {
          }
        );
      },
      onError: err => {
        console.log(err);
      }
    }).render(this.paypalElement.nativeElement);
    this.url = GLOBAL.url;
  }

  remove_carrito() {
    this.data_cupon = undefined;
    this.carrito.forEach((element, index) => {
      this._carritoService.remove_carrito(index).subscribe(
        response => {

        },
        error => {

        }
      );
    });
  }



  listar_carrito() {
    this._carritoService.preview_carrito().subscribe(
      response => {
        this.carrito = response.carrito;
        this.subtotal = 0;
        for (var _i = 0; _i < this.carrito.length; _i++) {
          this.carrito[_i].precio = (this.data_cupon && this.data_cupon.categoria._id == this.carrito[_i].producto.categoria) ?
            (this.carrito[_i].precio - ((this.carrito[_i].precio * this.data_cupon.descuento) / 100)) :
            this.carrito[_i].precio;
          this.subtotal = Math.round(this.subtotal + (this.carrito[_i].precio * this.carrito[_i].cantidad));
        }
        this.subtotal = Math.round(this.subtotal);
      },
      error => {
        this.carrito = [];
        this.subtotal = 0;
      }
    );
  }

  remove_producto(index) {
    this._carritoService.remove_carrito(index).subscribe(
      response => {
        this.listar_carrito();
      },
      error => {
      }
    );
  }


  get_data_cupon(event, cupon) {
    this.data_keyup = this.data_keyup + 1;
    if (cupon) {
      if (cupon.length == 13) {
        this._cuponService.get_cupon(cupon).subscribe(
          response => {
            this.data_cupon = response.cupone;
            this.msm_error_cupon = false;
            this.msm_success_cupon = true;
            this.listar_carrito();
          },
          error => {
            this.data_keyup = 0;
            this.data_cupon = undefined;
            this.msm_error_cupon = true;

            this.msm_success_cupon = false;
            this.listar_carrito();
          }
        );
      } else {
        this.data_cupon = undefined;
        this.data_keyup = 0;
        this.msm_error_cupon = false;
        this.msm_success_cupon = false;
        this.listar_carrito();

      }
    } else {
      this.data_keyup = 0;
      this.msm_error_cupon = false;
      this.msm_success_cupon = false;
      this.listar_carrito();

    }

  }

  select_postal(event, data) {
    //RESTAR PRECIO POSTAL ANTERIOR
    this.subtotal = Math.round(this.subtotal - parseInt(this.medio_postal.precio));

    this.medio_postal = {
      tipo_envio: data.titulo,
      precio: data.precio,
      tiempo: data.tiempo,
      dias: data.dias,
    };
    this.subtotal = Math.round(this.subtotal + parseInt(this.medio_postal.precio));

  }

  verify_data() {
    this.msm_error = '';
    $('#btn-verify-data').animate().hide();
    $('#btn-back-data').animate().show();

    $('#card-data-envio').animate().show();

    $('#card-pay').animate().show('fast');
    $('.cart-data-venta').animate().hide('fast');

    var info_cupon_string;
    if (this.data_cupon) {
      if (this.data_cupon.categoria) {
        info_cupon_string = this.data_cupon.descuento + '% de descuento en ' + this.data_cupon.categoria.nombre;
      }
    }
    this.data_venta = {
      total_pagado: this.subtotal,
      codigo_cupon: this.cupon,
      info_cupon: info_cupon_string,
      metodo_pago: 'Paypal',
      detalles: this.carrito
    };

    this.show_alternatice_paymente = false;


  }

  back_data() {
    $('#btn-verify-data').animate().show();
    $('#btn-back-data').animate().hide();

    $('#card-data-envio').animate().hide();

    $('#card-pay').animate().hide('fast');
    $('.cart-data-venta').animate().show('fast');

    this.show_alternatice_paymente = true;
  }

  listar_newest() {
    this._productoService.listar_newest().subscribe(
      response => {
        this.news_productos = response.data;
        this.over = new Array(this.news_productos.length);
        this.over.fill(false);
      },
      error => {

      }
    );
  }

}
