import {Component, OnInit} from '@angular/core';
import {CategoriaService} from "../../services/categoria.service";
import {GLOBAL} from "../../services/GLOBAL";

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.css']
})
export class CollectionsComponent implements OnInit {

  public categorias;
  public url;

  public isMobile = true;


  constructor(
    private _categoriaService: CategoriaService,
  ) {
    this.url = GLOBAL.url;
  }

  ngOnInit(): void {
    this.data_categorias();

    if (window.screen.width > 600) {
      this.isMobile = false;
    }
  }

  data_categorias() {

    this._categoriaService.listar('').subscribe(
      response => {
        this.categorias = response.categorias;
      },
      error => {

      }
    );
  }

}
