
    <div class="cz-sidebar-static rounded-lg box-shadow-lg px-0 pb-0 mb-5 mb-lg-0">
      <div class="px-4 mb-4">
        <div class="media align-items-center">
          <div class="img-thumbnail rounded-circle position-relative" style="width: 6.375rem;"><span class="badge badge-warning" data-toggle="tooltip" title="Reward points">384</span><img class="rounded-circle" [src]="url+'user/data/'+user.perfil" alt="Susan Gardner"></div>
          <div class="media-body pl-3">
            <h3 class="font-size-base mb-0">{{identity.first_name}}</h3><span class="text-accent font-size-sm">{{identity.email}}</span>
          </div>
        </div>
      </div>
     
      <div class="bg-secondary px-4 py-3">
        <h3 class="font-size-sm mb-0 text-muted">Opciones</h3>
      </div>
      <ul class="list-unstyled mb-0">
        <li class="border-bottom mb-0">
            <a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/cuenta/perfil']" routerLinkActive="active">
                <i class="czi-user opacity-60 mr-2"></i>
                Mis datos
            </a>
        </li>
        <li class="border-bottom mb-0">
            <a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/cuenta/direcciones']" routerLinkActive="active">
                <i class="czi-location opacity-60 mr-2"></i>Direcciones
            </a>
        </li>
        <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/cuenta/ordenes']" routerLinkActive="active"><i class="czi-bag opacity-60 mr-2"></i>Ordenes</a></li>

        <li class="border-bottom mb-0">
            <a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/cuenta/shop-cart']" routerLinkActive="active">
                <i class="czi-cart opacity-60 mr-2"></i>Mi carrito
            </a>
        </li>
        <li class="d-lg-none border-top mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" href="account-signin.html"><i class="czi-sign-out opacity-60 mr-2"></i>Sign out</a></li>
      </ul>
    </div>
