import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from "./GLOBAL";

@Injectable({
  providedIn: 'root'
})
export class HitosService {
  public url;
  constructor(
    private _http : HttpClient,
  ) {
    this.url = GLOBAL.url;
  }
  listar():Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.get(this.url + 'milestone',{headers:headers})
  }

  eliminar(id):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.delete(this.url + 'milestone/eliminar/'+id,{headers:headers})
  }

  registro(data):Observable<any>{
    var fd = new FormData();
    fd.append('title',data.title);
    fd.append('description',data.description);
    return this._http.post(this.url + 'milestone/registro',fd);
  }

  update(data):Observable<any>{
    const fd = new FormData();
    fd.append('title',data.title);
    fd.append('description',data.description);
    return this._http.put(this.url + '/milestone/editar/' + data._id,fd);
  }


}
