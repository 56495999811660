<!-- Account menu toggler (hidden on screens larger 992px)-->
<div class="d-block d-lg-none p-4"><a class="btn btn-outline-accent d-block" href="#account-menu" data-toggle="collapse"><i class="czi-menu mr-2"></i>Menú lateral</a></div>
<!-- Actual menu-->
<div class="cz-sidebar-static h-100 p-0">
  <div class="secondary-nav collapse border-right" id="account-menu">
    <div class="bg-secondary p-4">
      <h3 class="font-size-sm mb-0 text-muted">General</h3>
    </div>
    <ul class="list-unstyled mb-0">
      <li class="border-bottom mb-0">
        <a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/dashboard']" routerLinkActive="active" >
          <i class="czi-home opacity-60 mr-2"></i> Dashboard
        </a>
      </li>
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/portada']" routerLinkActive="active" ><i class="czi-check opacity-60 mr-2"></i>Portada</a></li>

    </ul>
    <div class="bg-secondary p-4">
      <h3 class="font-size-sm mb-0 text-muted">Tienda</h3>
    </div>
    <ul class="list-unstyled mb-0">
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/categorias']" routerLinkActive="active" ><i class="czi-heart opacity-60 mr-2"></i>Colecciones</a></li>
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/productos']" routerLinkActive="active"><i class="czi-heart-circle opacity-60 mr-2"></i>Productos</a></li>
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/ventas/modulo']" routerLinkActive="active" ><i class="czi-package opacity-60 mr-2"></i>Ordenes</a></li>
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/mensajes']" routerLinkActive="active" ><i class="czi-chat opacity-60 mr-2"></i>Mensajes</a></li>
    </ul>

<!--
    <div class="bg-secondary p-4">
      <h3 class="font-size-sm mb-0 text-muted">Logistica</h3>
    </div>
    <ul class="list-unstyled mb-0">
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/medios-postales']" routerLinkActive="active"><i class="czi-truck opacity-60 mr-2"></i>Medios de envios</a></li>
    </ul>-->

    <div class="bg-secondary p-4">
      <h3 class="font-size-sm mb-0 text-muted">Entradas</h3>
    </div>
    <ul class="list-unstyled mb-0">
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/articles']" routerLinkActive="active" ><i class="czi-bookmark opacity-60 mr-2"></i>Artículos</a></li>
    </ul>
    <ul class="list-unstyled mb-0">
    </ul>
    <ul class="list-unstyled mb-0">
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/preguntas']" routerLinkActive="active" ><i class="czi-message opacity-60 mr-2"></i>Preguntas Frecuentes</a></li>
      <li class="border-bottom mb-0"><a class="nav-link-style d-flex align-items-center px-4 py-3" [routerLink]="['/admin/hitos']" routerLinkActive="active" ><i class="czi-star opacity-60 mr-2"></i>Hitos AMAG</a></li>
    </ul>
  </div>
</div>
