<app-aside></app-aside>

<div class="page-title-overlap pt-4" style="background-color: #262626;">
  <app-jumbo></app-jumbo>
</div>

<div class="container mb-5 pb-3">
  <div class="bg-light box-shadow-lg rounded-lg overflow-hidden">
    <div class="row">
      <!-- Sidebar-->
      <aside class="col-lg-4">
        <app-menu></app-menu>
      </aside>
      <!-- Content-->
      <section class="col-lg-8 pt-lg-4 pb-4 mb-3">
        <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
          <div class="row">
            <div class="col-lg-12">
              <div class="border-bottom mt-lg-2 pb-2 mb-5">
                <h1 class="mt-lg-4">Registro de producto</h1>
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                  <p class="text-muted">Complete correctamente el formulario.</p>

                </div>
              </div>

              <div *ngIf="msm_error" (click)="close_alert()" class="alert alert-danger alert-dismissible fade show"
                   role="alert">
                {{msm_error}}
                <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span
                  aria-hidden="true">×</span></button>
              </div>

              <div *ngIf="msm_success" (click)="close_alert()" class="alert alert-success alert-dismissible fade show"
                   role="alert">
                {{msm_success}}
                <button class="close" type="button" data-dismiss="alert" aria-label="Close"><span
                  aria-hidden="true">×</span></button>
              </div>
              <form #productoForm="ngForm" (ngSubmit)="onSubmit(productoForm)">
                <div class="card border-0 box-shadow">
                  <div class="card-header">
                    <a class="btn btn-accent mb-2 mr-1" [routerLink]="['/admin/productos']"><span
                      class="czi-arrow-left"></span> Regresar</a>
                    <button class="btn btn-success mb-2 mr-1" type="submit">Registrar</button>
                  </div>
                  <div class="card-body">
                    <div class="tab-content" style="margin-top: 20px;">
                      <div class="tab-pane fade active show">
                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Titulo</label>
                          <div class="col-md-10">
                            <input class="form-control" type="text" placeholder="Titulo de la entrada" #titulo="ngModel"
                                   name="titulo" [(ngModel)]="producto.titulo" required>
                          </div>
                        </div>

                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Banner</label>
                          <div class="col-md-10">
                            <!-- Drag and drop file upload -->
                            <div class="cz-file-drop-area">
                              <div *ngIf="!producto['poster']" class="cz-file-drop-icon czi-cloud-upload"></div>
                              <div *ngIf="producto['poster']" class="cz-file-drop-preview img-thumbnail rounded">
                                <img src="{{imgSelect['poster']}}">
                              </div>
                              <span class="cz-file-drop-message">Suelta la imagen aquí</span>
                              <input type="file" class="cz-file-drop-input" (change)="imgSelected($event, 'poster')">

                            </div>
                            <p class="small text-muted mt-2">Tamaño recomendado: 500px X 660px - Peso recomendado: 20kb</p>
                          </div>
                        </div>
                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Color banner</label>
                          <div class="col-md-10">
                            <input class="form-control" type="text" placeholder="Indique el color para ordenar los productos" #color="ngModel"
                                   name="color" [(ngModel)]="producto.color" required>
                            <p class="small text-muted mt-2">Se agruparán los productos por color y se organizarán alfabéticamente.</p>
                          </div>
                        </div>
                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Banner
                            Hover</label>
                          <div class="col-md-10">
                            <!-- Drag and drop file upload -->
                            <div class="cz-file-drop-area">
                              <div *ngIf="!producto['hoverPoster']" class="cz-file-drop-icon czi-cloud-upload"></div>
                              <div *ngIf="producto['hoverPoster']" class="cz-file-drop-preview img-thumbnail rounded">
                                <img src="{{imgSelect['hoverPoster']}}">
                              </div>
                              <span class="cz-file-drop-message">Suelta la imagen aquí</span>
                              <input type="file" class="cz-file-drop-input"
                                     (change)="imgSelected($event, 'hoverPoster')">
                            </div>
                            <p class="small text-muted mt-2">Tamaño recomendado: 500px X 660px - Peso recomendado: 20kb</p>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-md-2 col-form-label font-weight-medium" for="textarea-input">Descripción del
                            producto</label>
                          <div class="col-md-10">
                            <textarea class="form-control" id="textarea-input" rows="5"
                                      placeholder="Detalle corto del producto." #info_short="ngModel" name="info_short"
                                      [(ngModel)]="producto.info_short" required></textarea>
                          </div>
                        </div>
                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Detalles</label>
                          <div class="col-md-10">
                            <div class="row">
                              <div class="col-lg-4">
                                <input class="form-control" type="number" placeholder="Precio actual"
                                       #precio_ahora="ngModel" name="precio_ahora" min="0"
                                       [(ngModel)]="producto.precio_ahora" required>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="form-group row align-items-center">
                          <label class="col-md-2 col-form-label font-weight-medium" for="text-input">Colección</label>
                          <div class="col-md-10">
                            <div class="row">
                              <div class="col-lg-6">
                                <div class="form-group">
                                  <label for="select-input">Seleccione la colección</label>
                                  <select class="form-control custom-select" (change)="select_categoria()"
                                          id="select-input" [(ngModel)]="producto.categoria" name="categoria" required>
                                    <option selected disabled value="">SELECCIONAR</option>

                                    <option *ngFor="let item of data_categorias"
                                            [value]="item._id">{{item.categoria}}</option>
                                  </select>

                                </div>
                              </div>
                              <!--                                                 <div class="col-lg-6">
                                                                                    <div class="form-group">
                                                                                        <label for="select-input">Subcategoría</label>
                                                                                        <select disabled class="form-control custom-select" id="select-sub" #subcategoria="ngModel" [(ngModel)]="producto.subcategoria" name="subcategoria" required>
                                                                                            <option selected disabled value="">SELECCIONAR</option>
                                                                                            <option *ngFor="let item of data_subcategorias" [value]="item">{{item}}</option>

                                                                                        </select>
                                                                                    </div>
                                                                               </div>-->

                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>

        </div>
      </section>
    </div>
  </div>
</div>
