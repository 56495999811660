<header class="box-shadow-sm">
  <div class="container-fluid graycolor">
    <div class="row">

      <div class="col-4 col-md-4">
        <a target="_blank" href="https://www.instagram.com/amagbynr/">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
               class="bi bi-instagram mr-2 mt-2 mb-2" style="color:
black;" viewBox="0 0 16 16">
            <path
              d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
          </svg>
        </a>
        <a target="_blank" href="https://www.facebook.com/amagbynr/">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
               class="bi bi-facebook mt-2 mb-2" style="color:
black;"
               viewBox="0 0 16 16">
            <path
              d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
          </svg>
        </a>

      </div>

      <div class="col-4 col-md-4 none-mobile">
        <h5 class="text-center poppins smalltext textblack mt-3" style=" cursor: pointer;"><a [routerLink]="['/productos/'+ categoriaLatest?.nombre?.toLowerCase().trim()]" style="color: #333333">Nueva colección</a></h5>
      </div>

      <div class="col-8 col-md-4">
        <a><h5 class="text-right poppins smalltext mt-3" style="">Contacto: +506 7260 1197</h5></a>
      </div>

    </div>
  </div>


  <nav class="navbar navbar-expand-lg navbar-light bg-light none-mobile none-ipad">

    <div class="col-1 col-md-2 col-lg-2 col-xl-3">
      <a [routerLink]="['/home/inicio']"><picture>
        <source srcset="assets/img/logoamag.svg" type="image/svg">
        <source srcset="assets/img/logoamag.png" type="image/png">
        <img alt="imagen logo" class="ml-3 mt-2 mb-2 logo-menu-desktop" src="assets/img/logoamag.png" style="">
      </picture></a>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    </div>


    <div class="remove-all-margin-padding col-8 col-md-8 col-lg-8 col-xl-6" >

      <div class="collapse navbar-collapse flex-wrap justify-content-center" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item active m-3" style="white-space: nowrap;">
            <a class="poppins menutext textblack btn-line" [routerLink]="['/productos/'+ categoriaLatest?.nombre?.toLowerCase().trim()]" href="#">Lo nuevo</a>
          </li>
          <li class="nav-item m-3">
            <a class="poppins menutext textblack btn-line" [routerLink]="['/colecciones']" href="#">Colecciones</a>
          </li>
          <li class="nav-item m-3" style="white-space: nowrap;">
            <a class="poppins menutext textblack btn-line" [routerLink]="['/sobrenosotros']" href="#">Sobre AMAG</a>
          </li>

          <li class="nav-item m-3">
            <a class="poppins menutext textblack btn-line" [routerLink]="['/home/materiales']">Materiales</a>
          </li>

          <li class="nav-item m-3">
            <a class="poppins menutext textblack btn-line" [routerLink]="['/articulos']" href="#">Blog</a>
          </li>

          <li class="nav-item m-3">
            <a class="poppins menutext textblack btn-line" [routerLink]="['/contacto']" href="#">Contacto</a>
          </li>

          <li class="nav-item m-3">
            <a class="poppins menutext textblack btn-line" [routerLink]="['/preguntasfrecuentes']" href="#">FAQ's</a>
          </li>
        </ul>
      </div>

    </div>


    <div class="col-3 col-md-2 col-lg-2 col-xl-3">
      <div class="navbar-tool dropdown justify-content-end">

        <a class="navbar-tool-icon-box bg-secondary dropdown-toggle" [routerLink]="['/cuenta/shop-cart']">
          <i class="navbar-tool-icon czi-bag"></i></a><a
        class="navbar-tool-text" [routerLink]="['/cuenta/shop-cart']"><small>My Bag</small>{{carrito.length}} Piezas</a>


            <!-- Cart dropdown-->
           <div class="dropdown-menu dropdown-menu-left" style="width: 100% !important;" *ngIf="carrito.length != 0">
              <div class="widget widget-cart px-3 pt-2 pb-3">
                <div style="height: 15rem;" data-simplebar data-simplebar-auto-hide="false">
                  <div class="widget-cart-item pb-2 border-bottom" *ngFor="let item of carrito; index as i">
                    <button class="close text-dark" type="button" aria-label="Remove"
                            (click)="remove_producto(i)">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <div class="media align-items-center"><a class="d-block mr-2"
                                                             [routerLink]="['/producto/',item.producto.slug]"><img
                      width="64" [src]="url+'productos_img_data/resources/thumbnails/'+item.producto.poster"
                      alt="Product"/></a>
                      <div class="media-body">
                        <h6 class="poppins smalltext" style="color: black;"><a
                          [routerLink]="['/producto/',item.producto.slug]" style="color: black;">{{item.producto.titulo.substr(0, 25)}}</a>
                        </h6>
                        <div class="widget-product-meta"><span class="text-dark mr-2">${{item.precio}}</span><span
                          class="text-muted">x {{item.cantidad}}</span></div>
                      </div>
                    </div>
                  </div>

            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center py-3">
              <div class="font-size-sm mr-2 py-2">
                      <span class="text-muted">
                        Subtotal:
                      </span>
                <span class="text-dark font-size-base ml-1">
                        ${{subtotal}}
                      </span>
              </div>

            </div>
            <a class="btn btn-dark btn-sm btn-block" [routerLink]="['/cuenta/shop-cart']">
              <i class="czi-card mr-2 font-size-base align-middle"></i>Carrito completo
            </a>
          </div>
        </div>
      </div>
    </div>

  </nav>

  <nav class="navbar navbar-expand-lg navbar-light bg-light nav-ipad-mobile">
    <div class="row">
      <div class="col-9 col-md-10">
        <a class="navbar-brand logo-mobile-devices ml-md-4" [routerLink]="['/home/inicio']" href="#"><img src="assets/img/logoamag.png"></a>
      </div>

      <div class="col-3 col-md-2">

        <button class="navbar-toggler ml-3 ml-md-0" type="button" data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

      </div>
    </div>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">

      <ul class="navbar-nav text-center mt-3">

        <li class="nav-item m-2">
          <a class="poppins bigtext textblack btn-line" [routerLink]="['/sobrenosotros']" href="#">Nosotros
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                 class="bi bi-arrow-right-circle ml-2" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
            </svg>
          </a>
        </li>

        <li class="nav-item m-2">
          <a class="poppins bigtext textblack btn-line" [routerLink]="['/contacto']">Contacto
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                 class="bi bi-arrow-right-circle ml-2" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
            </svg>
          </a>
        </li>

        <li class="nav-item m-2">
          <a class="poppins bigtext textblack btn-line" [routerLink]="['/home/materiales']">Materiales
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                 class="bi bi-arrow-right-circle ml-2" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
            </svg>
          </a>
        </li>

        <li class="nav-item m-2">
          <a class="poppins bigtext textblack btn-line" [routerLink]="['/articulos']" href="#">Blog
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                 class="bi bi-arrow-right-circle ml-2" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
            </svg>
          </a>
        </li>

        <li class="nav-item m-2">
          <a class="poppins bigtext textblack btn-line" [routerLink]="['/preguntasfrecuentes']" href="#">Preguntas Frecuentes
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                 class="bi bi-arrow-right-circle ml-2" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
            </svg>
          </a>
        </li>
      </ul>

      <div class="text-center mt-5">
        <a class="" target="_blank" href="https://www.instagram.com/amagbynr/">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-instagram textblack mr-2"
               viewBox="0 0 16 16">
            <path
              d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
          </svg>
        </a>
        <a class="" target="_blank" href="https://www.facebook.com/amagbynr/">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-facebook textblack ml-2"
               viewBox="0 0 16 16">
            <path
              d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
          </svg>
        </a>
        <p class="poppins regulartext textblack mt-3 mb-3">Whatsapp: +506 7260 1197</p>
      </div>

    </div>
  </nav>



</header>
