<app-nav></app-nav>


<section class="rosecover">

</section>

<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="bebas rosetitle textwhite margin-text-cover">Entradas By Nolys
        </h1>
        <span class="decor-cover"></span>
      </div>
    </div>
  </div>
</section>


<section>
  <div class="container">
    <div class="row">

      <div class="col-12 mb-5" *ngFor="let article of articles">
        <div class="card text-white text-center mt-5 mb-3" style="border-radius: 0 !important; background-color: transparent;" *ngIf="!isMobile">
          <img [src]="url+'article/resources/thumbnails/'+article.banner" class="card-img" alt="..." style="border-radius: 0 !important; background-color: #b1a0a2;">
          <div class="card-img-overlay">
            <div class="container">
              <div class="row">
                <div class="col-2"></div>
                <div class="col-8">
                  <h5 class="card-title poppinsbold articletitle textwhite margin-top-card">{{article.nombre}}</h5>
                </div>
                <div class="col-2"></div>
              </div>
            </div>
            <div class="container">
              <div class="row">

                <div class="col-1 col-md-4"></div>
                <div class="col-10 col-md-4">
                  <a [routerLink]="['/articulo/'+article.slug]">
                    <button class="btn btn-light btn-sm btn-block" style="border-radius: 0 !important;">Leer Artículo
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                           class="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                      </svg>
                    </button>
                  </a>
                </div>
                <div class="col-1 col-md-4"></div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 mb-2 mt-2" *ngFor="let article of articles">
        <div class="card bg-dark text-white text-center mt-3 mb-3" style="border-radius: 0 !important; background-color: transparent;" *ngIf="isMobile">
          <img [src]="url+'article/resources/thumbnails/'+article.mobile_banner" class="card-img" alt="..." style="border-radius: 0 !important; background-color: #b1a0a2;">
          <div class="card-img-overlay">
            <h5 class="card-title poppinsbold articletitle textwhite margin-top-card">{{article.nombre}}</h5>
            <div class="container">
              <div class="row">

                <div class="col-1 col-md-4"></div>
                <div class="col-10 col-md-4">
                  <a [routerLink]="['/articulo/'+article.slug]">
                  <button class="btn btn-light btn-sm btn-block" style="border-radius: 0 !important;">Leer Artículo
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         class="bi bi-arrow-right" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                            d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                  </button></a>
                </div>
                <div class="col-1 col-md-4"></div>

              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>


</section>


<app-foot></app-foot>


