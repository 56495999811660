<app-aside></app-aside>


<div class="page-title-overlap pt-4" style="background-color: #262626;">
  <app-jumbo></app-jumbo>
</div>

<div class="container mb-5 pb-3">
  <div class="bg-light box-shadow-lg rounded-lg overflow-hidden">
    <div class="row">
      <!-- Sidebar-->
      <aside class="col-lg-4">
        <app-menu></app-menu>
      </aside>
      <!-- Content-->
      <section class="col-lg-8 pt-lg-4 mb-3">
        <div class="pt-2 px-4 pl-lg-0 pr-xl-5">
          <div class="row">


            <div class="col-lg-12">
              <div class="border-bottom mt-lg-2 pb-2">
                <h2 class="mt-lg-4">Ordenes</h2>
                <div class="d-flex flex-wrap flex-md-nowrap justify-content-between">
                  <p class="text-muted">Todas las ventas registradas en el ecommerce.</p>

                </div>
              </div>

            </div>

            <div class="col-lg-12 pt-4">
              <div class="card">
                <div class="card-body">
                  <!-- Nav pills -->
                  <ul class="nav nav-pills mb-3" role="tablist">
                    <li class="nav-item">
                      <a href="#" class="nav-link active" id="pills-home1" data-toggle="pill" role="tab"
                         aria-controls="home1" aria-selected="true" (click)="pills('home1')">
                        <i class="czi-bookmark mr-2"></i>
                        Fechas
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#" class="nav-link" id="pills-profile1" data-toggle="pill" role="tab"
                         aria-controls="profile1" aria-selected="false" (click)="pills('profile1')">
                        <i class="czi-flag mr-2"></i>
                        Estado
                      </a>
                    </li>
                    <li class="nav-item">
                      <a href="#" class="nav-link" id="pills-messages1" data-toggle="pill" role="tab"
                         aria-controls="messages1" aria-selected="false" (click)="pills('messages1')">
                        <i class="czi-key mr-2"></i>
                        Código
                      </a>
                    </li>
                  </ul>

                  <!-- Pills content -->
                  <div class="tab-content">
                    <div id="home1" class="tab-pane fade show active" role="tabpanel" aria-labelledby="pills-home1">
                      <div class="btn-toolbar pt-2" role="toolbar" aria-label="Settings toolbar">
                        <form #searchForm="ngForm" (ngSubmit)="search('fecha',null)">
                          <div class="btn-group mr-2 mb-2" role="group" aria-label="Apply settings">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                    style="border-top-right-radius: 0px;border-bottom-right-radius: 0px">
                                 Año
                              </span>
                            </div>
                            <select name="" class="form-control custom-select"
                                    style="border-top-left-radius: 0px;border-bottom-left-radius: 0px" name="year"
                                    [(ngModel)]="year">
                              <option value="" selected disabled>Seleccionar</option>
                              <option value="2019">2019</option>
                              <option value="2020">2020</option>

                            </select>

                          </div>
                          <div class="btn-group mr-2 mb-2" role="group" aria-label="Apply settings">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                    style="border-top-right-radius: 0px;border-bottom-right-radius: 0px">
                                 Mes
                              </span>
                            </div>
                            <select name="" class="form-control custom-select"
                                    style="border-top-left-radius: 0px;border-bottom-left-radius: 0px" name="mes"
                                    [(ngModel)]="mes">
                              <option value="0" selected>Todos</option>
                              <option value="1">Enero</option>
                              <option value="2">Febrero</option>
                              <option value="3">Marco</option>
                              <option value="4">Abril</option>
                              <option value="5">Mayo</option>
                              <option value="6">Junio</option>
                              <option value="7">Julio</option>
                              <option value="8">Agosto</option>
                              <option value="9">Septiembre</option>
                              <option value="10">Octubre</option>
                              <option value="11">Noviembre</option>
                              <option value="12">Diciembre</option>
                            </select>

                          </div>
                          <div class="btn-group mr-2 mb-2" role="group" aria-label="Apply settings">
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                    style="border-top-right-radius: 0px;border-bottom-right-radius: 0px">
                                Día
                              </span>
                            </div>
                            <input type="number" class="form-control" max="31" min="0"
                                   style="border-top-left-radius: 0px;border-bottom-left-radius: 0px" name="dia"
                                   [(ngModel)]="dia">
                          </div>


                          <div class="btn-group mr-2 mb-2" role="group" aria-label="Settings group">
                            <button class="btn btn-dark btn-icon" type="submit">
                              <i class="czi-search"></i>
                            </button>

                          </div>
                        </form>

                      </div>
                    </div>
                    <div id="profile1" class="tab-pane fade" role="tabpanel" aria-labelledby="pills-profile1">
                      <div class="btn-toolbar pt-2" role="toolbar" aria-label="Settings toolbar">
                        <form #searchForm="ngForm" (ngSubmit)="search('estado',null)">
                          <div class="btn-group mr-2 mb-2" role="group" aria-label="Apply settings">
                            <div class="input-group-prepend">
                               <span class="input-group-text"
                                     style="border-top-right-radius: 0px;border-bottom-right-radius: 0px">
                                  Estado
                               </span>
                            </div>
                            <select name="" class="form-control custom-select"
                                    style="border-top-left-radius: 0px;border-bottom-left-radius: 0px" name="estado"
                                    [(ngModel)]="estado">
                              <option value="Venta en proceso" selected>Venta en proceso</option>
                              <option value="Enviado">Enviado</option>
                            </select>

                          </div>

                          <div class="btn-group mr-2 mb-2" role="group" aria-label="Settings group">
                            <button class="btn btn-dark btn-icon" type="submit">
                              <i class="czi-search"></i>
                            </button>

                          </div>
                        </form>

                      </div>
                    </div>
                    <div id="messages1" class="tab-pane fade" role="tabpanel" aria-labelledby="pills-messages1">
                      <div class="btn-toolbar pt-2" role="toolbar" aria-label="Settings toolbar">
                        <form #searchForm="ngForm" (ngSubmit)="search('codigo',null)">
                          <div class="btn-group mr-2 mb-2" role="group" aria-label="Apply settings">
                            <div class="input-group-prepend">
                               <span class="input-group-text"
                                     style="border-top-right-radius: 0px;border-bottom-right-radius: 0px">
                                  Código
                               </span>
                            </div>
                            <input type="text" class="form-control" placeholder="Código de venta" name="codigo"
                                   [(ngModel)]="codigo">
                          </div>

                          <div class="btn-group mr-2 mb-2" role="group" aria-label="Settings group">
                            <button class="btn btn-dark btn-icon" type="submit">
                              <i class="czi-search"></i>
                            </button>
                          </div>
                        </form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-4">
              <div class="card">
                <div class="card-header">
                  <div class="btn-group mr-2 mb-2" role="group" aria-label="Apply settings"
                       style="margin-bottom: 0px !important;">
                    <div class="input-group-prepend">
                          <span class="input-group-text"
                                style="border-top-right-radius: 0px;border-bottom-right-radius: 0px">
                            <span class="czi-arrows-vertical"></span> &nbsp; Orden
                          </span>
                    </div>
                    <select name="" class="form-control custom-select"
                            style="border-top-left-radius: 0px;border-bottom-left-radius: 0px" name="orden"
                            [(ngModel)]="orden" (change)="search(tipo,orden)">
                      <option value="fecha+" selected>Fecha (+ to -)</option>
                      <option value="pagado+">Total pagado (+ to -)</option>
                      <option value="fecha-">Fecha (- to +)</option>
                      <option value="pagado-">Total pagado (- to +)</option>
                    </select>
                    <button type="button" class="btn btn-accent" (click)="init_data()"><span class="czi-filter"></span>
                      Restaurar filtro
                    </button>
                  </div>

                  <div class="btn-group" role="group" aria-label="Solid button group">
                    <button type="button" id="btn-uno" class="btn btn-secondary" (click)="method_data_view(1)"><span
                      class="czi-server"></span></button>
                    <button type="button" id="btn-dos" class="btn btn-secondary" (click)="method_data_view(2)"><span
                      class="czi-menu"></span></button>


                  </div>
                </div>
                <div class="card-body" *ngIf="data_view == 1">
                  <div class="row">
                    <div class="col-lg-12 pb-2"
                         *ngFor="let item of ventas| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                      <div class="card">
                        <div class="card-header">
                          <h3 class="product-title font-size-base" style="margin-bottom: 0px;">
                            <ng-container *ngIf="item.estado == 'Venta en proceso'">
                              <span class="badge badge-accent">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Enviado'">
                              <span class="badge badge-success">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Cancelado'">
                              <span class="badge badge-danger">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Reembolsado'">
                              <span class="badge badge-danger">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Finalizado'">
                              <span class="badge badge-primary">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <a>Compra: N°-{{item._id.toUpperCase()}}</a></h3>

                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="col-lg-5">
                              <div class="media-body pt-2">
                                <div class="font-size-sm"><span class="text-muted mr-2">Cliente:</span>
                                  {{item.destinatario}}
                                </div>
                                <div class="font-size-sm"><span class="text-muted mr-2">Ubicación:</span>
                                  <span>{{item.provincia}} - {{item.ciudad}} - {{item.referencia}}</span>
                                </div>
                                <div class="font-size-sm"><span class="text-muted mr-2">Dirección:</span>
                                  {{item.direccion}}
                                </div>
                                <div class="font-size-sm"><span class="text-muted mr-2">Fecha:</span>
                                  {{ item.createdAt | date:'MMM d, y, h:mm:ss a' }}
                                </div>
                                <div class="font-size-lg text-accent pt-2"><span
                                  class="text-muted mr-2">Total pagado:</span>$ {{item.total_pagado}}</div>
                              </div>
                            </div>

                            <div class="col-lg-7">
                              <div class="btn-toolbar" role="toolbar" aria-label="Settings toolbar"
                                   *ngIf="item.estado == 'Venta en proceso' || item.estado == 'Enviado' || item.estado == 'Finalizado'">
                                <div class="btn-group mr-2 mb-2" role="group" aria-label="Settings group">
                                  <a [routerLink]="['/admin/venta/modulo/detalle/',item._id]"
                                     class="btn btn-dark btn-icon">
                                    <i class="czi-basket"></i> &nbsp; Detalles
                                  </a>

                                  <div class="btn-group" role="group">
                                    <button type="button" class="btn btn-primary" aria-haspopup="true"
                                            aria-expanded="false" *ngIf="item.estado == 'Venta en proceso'"  data-toggle="modal" [attr.data-target]="'#trak-'+item._id">
                                      <span class="czi-truck"></span>
                                    </button>
                                  </div>
                                </div>
                                <!-- <div class="btn-group mr-2 mb-2" role="group" aria-label="Apply settings">
                                  <button type="button" class="btn btn-info">
                                    <i class="czi-check mr-2"></i>
                                    Apply
                                  </button>
                                </div> -->

                              </div>
                              <ng-container *ngIf="item.estado == 'Cancelado'">
                                <a [routerLink]="['/admin/cancelacion/modulo']" routerLinkActive="router-link-active"
                                   class="btn btn-danger"><span class="czi-turn-off"></span>&nbsp; Ir a
                                  cancelaciones</a>
                              </ng-container>
                            </div>
                          </div>
                          <div class="modal fade" [attr.id]="'trak-'+item._id" tabindex="-1" role="dialog"
                               style="display: none;" aria-hidden="true">
                            <form #trackForm="ngForm" (ngSubmit)="setTrack(item._id)">
                              <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h4 class="modal-title">Orden <span class="text-muted font-size-sm">Enviada?</span>
                                    </h4>
                                    <button class="close" type="button" data-dismiss="modal" aria-label="Close"><span
                                      aria-hidden="true">×</span></button>
                                  </div>
                                  <div class="modal-body">
                                    <div class="row">
                                      <div class="col-lg-12">
                                        <div class="col-lg-12 mt-2 text-center">
                                          ¿Quieres registar esta orden como enviada?
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button class="btn btn-secondary btn-sm" type="button" data-dismiss="modal">No
                                    </button>
                                    <button class="btn btn-primary btn-shadow btn-sm" type="submit">Sí</button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body" *ngIf="data_view == 2">
                  <!-- Light table with contextual rows -->
                  <div class="table-responsive">
                    <table *ngIf="ventas.length >0" class="table">
                      <thead>
                      <tr>
                        <th>#</th>
                        <th>Código</th>
                        <th>Fecha</th>
                        <th>Estado</th>
                        <th>OP</th>
                      </tr>
                      </thead>
                      <tbody>
                      <ng-container
                        *ngFor="let item of ventas| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                        <tr class="table-success" *ngIf="item.estado == 'Enviado'">
                          <th scope="row">
                            <div class="custom-control custom-checkbox">
                              <input disabled class="custom-control-input" type="checkbox" [(ngModel)]="item.checked"
                                     (change)="select_id()" [attr.id]="item._id">
                              <label class="custom-control-label" [attr.for]="item._id"></label>
                            </div>
                          </th>
                          <td><span class="badge badge-dark">{{item._id.toUpperCase()}}</span></td>
                          <td class="font-size-sm">{{ item.createdAt | date:'MMM d, y' }}</td>
                          <td>
                            <ng-container *ngIf="item.estado == 'Venta en proceso'">
                              <span class="badge badge-accent">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Enviado'">
                              <span class="badge badge-success">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Cancelado'">
                              <span class="badge badge-danger">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Reembolsado'">
                              <span class="badge badge-danger">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Finalizado'">
                              <span class="badge badge-primary">{{item.estado}}</span>&nbsp;
                            </ng-container>
                          </td>
                          <td><a class="" [routerLink]="['/admin/venta/modulo/detalle/',item._id]"><span
                            class="czi-basket"></span></a></td>
                        </tr>
                        <tr class="table-accent" *ngIf="item.estado == 'Venta en proceso'">
                          <th scope="row">
                            <div class="custom-control custom-checkbox">
                              <input class="custom-control-input" type="checkbox" [(ngModel)]="item.checked"
                                     (change)="select_id()" [attr.id]="item._id">
                              <label class="custom-control-label" [attr.for]="item._id"></label>
                            </div>
                          </th>
                          <td><span class="badge badge-dark">{{item._id.toUpperCase()}}</span></td>
                          <td class="font-size-sm">{{ item.createdAt | date:'MMM d, y' }}</td>
                          <td>
                            <ng-container *ngIf="item.estado == 'Venta en proceso'">
                              <span class="badge badge-accent">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Enviado'">
                              <span class="badge badge-success">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Cancelado'">
                              <span class="badge badge-danger">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Reembolsado'">
                              <span class="badge badge-danger">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Finalizado'">
                              <span class="badge badge-primary">{{item.estado}}</span>&nbsp;
                            </ng-container>
                          </td>
                          <td><a class="" [routerLink]="['/admin/venta/modulo/detalle/',item._id]"><span
                            class="czi-basket"></span></a></td>
                        </tr>
                        <tr class="table-primary" *ngIf="item.estado == 'Finalizado'">
                          <th scope="row">
                            <div class="custom-control custom-checkbox">
                              <input disabled class="custom-control-input" type="checkbox" [(ngModel)]="item.checked"
                                     (change)="select_id()" [attr.id]="item._id">
                              <label class="custom-control-label" [attr.for]="item._id"></label>
                            </div>
                          </th>
                          <td><span class="badge badge-dark">{{item._id.toUpperCase()}}</span></td>
                          <td class="font-size-sm">{{ item.createdAt | date:'MMM d, y' }}</td>
                          <td>
                            <ng-container *ngIf="item.estado == 'Venta en proceso'">
                              <span class="badge badge-accent">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Enviado'">
                              <span class="badge badge-success">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Cancelado'">
                              <span class="badge badge-danger">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Reembolsado'">
                              <span class="badge badge-danger">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Finalizado'">
                              <span class="badge badge-primary">{{item.estado}}</span>&nbsp;
                            </ng-container>
                          </td>
                          <td><a class="" [routerLink]="['/admin/venta/modulo/detalle/',item._id]"><span
                            class="czi-basket"></span></a></td>
                        </tr>
                        <tr class="table-danger" *ngIf="item.estado == 'Cancelado'">
                          <th scope="row">
                            <div class="custom-control custom-checkbox">
                              <input disabled class="custom-control-input" type="checkbox" [(ngModel)]="item.checked"
                                     (change)="select_id()" [attr.id]="item._id">
                              <label class="custom-control-label" [attr.for]="item._id"></label>
                            </div>
                          </th>
                          <td><span class="badge badge-dark">{{item._id.toUpperCase()}}</span></td>
                          <td class="font-size-sm">{{ item.createdAt | date:'MMM d, y' }}</td>
                          <td>
                            <ng-container *ngIf="item.estado == 'Venta en proceso'">
                              <span class="badge badge-accent">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Enviado'">
                              <span class="badge badge-success">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Cancelado'">
                              <span class="badge badge-danger">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Reembolsado'">
                              <span class="badge badge-danger">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Finalizado'">
                              <span class="badge badge-primary">{{item.estado}}</span>&nbsp;
                            </ng-container>
                          </td>
                          <td><a class="" [routerLink]="['/admin/venta/modulo/detalle/',item._id]"><span
                            class="czi-basket"></span></a></td>
                        </tr>
                        <tr class="table-danger" *ngIf="item.estado == 'Reembolso'">
                          <th scope="row">
                            <div disabled class="custom-control custom-checkbox">
                              <input class="custom-control-input" type="checkbox" [(ngModel)]="item.checked"
                                     (change)="select_id()" [attr.id]="item._id">
                              <label class="custom-control-label" [attr.for]="item._id"></label>
                            </div>
                          </th>
                          <td><span class="badge badge-dark">{{item._id.toUpperCase()}}</span></td>
                          <td class="font-size-sm">{{ item.createdAt | date:'MMM d, y' }}</td>
                          <td>
                            <ng-container *ngIf="item.estado == 'Venta en proceso'">
                              <span class="badge badge-accent">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Enviado'">
                              <span class="badge badge-success">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Cancelado'">
                              <span class="badge badge-danger">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Reembolsado'">
                              <span class="badge badge-danger">{{item.estado}}</span>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="item.estado == 'Finalizado'">
                              <span class="badge badge-primary">{{item.estado}}</span>&nbsp;
                            </ng-container>
                          </td>
                          <td><a class="" [routerLink]="['/admin/venta/modulo/detalle/',item._id]"><span
                            class="czi-basket"></span></a></td>
                        </tr>
                      </ng-container>

                      </tbody>
                    </table>
                  </div>

                </div>

                <div class="card-footer" *ngIf="data_view == 2">
                  <ng-container *ngIf="count_selects">
                    <button class="btn btn-success btn-sm" (click)="update_envio()">
                      <span class="czi-plane"></span> Marcar como enviados
                    </button>
                  </ng-container>
                  <ng-container *ngIf="!count_selects">
                    <button class="btn btn-success btn-sm" disabled>
                      <span class="czi-plane"></span> Marcar como enviados
                    </button>
                  </ng-container>
                </div>

              </div>

            </div>
            <div class="col-lg-12 mt-2">

              <ngb-pagination
                [(page)]="page"
                [pageSize]="pageSize"
                [collectionSize]="ventas.length">
              </ngb-pagination>
            </div>
          </div>

        </div>
      </section>
    </div>
  </div>
</div>
