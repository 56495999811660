<app-nav></app-nav>


<section class="rosecover">

</section>

<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="bebas rosetitle textwhite margin-text-cover">Colecciones AMAG</h1>
        <span class="decor-cover"></span>
      </div>
    </div>
  </div>
</section>


<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <h2 class="modernline frasesize textblack mt-5 mb-5">Dejá que tu atuendo hable por vos</h2>
        <!--<p class="poppins regulartext textblack mt-4">Lorem ipsum dolor sit amet, consectetuer adipiscing elit sed
          diam.</p>-->
      </div>
    </div>
  </div>
</section>


<section class="mt-3">
  <div class="container">
    <div class="row">

      <div class="col-12 col-md-12 col-lg-6 col-xl-6 mb-5" *ngFor="let categoria of categorias;let indice=index">

        <div class="card text-white mb-2" *ngIf="!isMobile" style="border-radius: 0 !important; border-color: transparent; background-color: transparent;">
          <img [src]="url+'categoria/resources/thumbnails/'+ categoria.banner" alt="..." style="border-radius: 0 !important; background-color: #b1a0a2">
          <div class="card-img-overlay">
            <h5 class="card-title poppinsbold hugetext textwhite margin-top-card" style="">{{categoria.nombre}}</h5>
            <a [routerLink]="['/productos/'+categoria.nombre.toLowerCase().trim()]">
              <button type="button" class="btn btn-light btn-sm" style="border-radius: 0 !important;">Ver colección
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
              </svg>
            </button></a>
          </div>
        </div>

        <div class="card bg-light text-white mb-3" *ngIf="isMobile" style="border-radius: 0 !important;">
          <img [src]="url+'categoria/resources/thumbnails/'+ categoria.mobile_banner" class="card-img" alt="..." style="border-radius: 0 !important; background-color: #b1a0a2;">
          <div class="card-img-overlay">
            <h5 class="card-title poppinsbold title-mobile textwhite margin-top-card" style="line-height: 1.1;">{{categoria.nombre}}</h5>
            <a [routerLink]="['/productos/'+categoria.nombre.toLowerCase().trim()]"><button type="button" class="btn btn-light btn-sm" style="border-radius: 0 !important;">Ver colección
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
              </svg>
            </button></a>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>



<app-foot></app-foot>
