<app-nav></app-nav>

<section class="" *ngIf="!isMobile">
  <div class="container-fluid remove-all-margin-padding">
    <div class="row remove-all-margin-padding">
      <div class="col-12 remove-all-margin-padding">
        <img [src]="url+'categoria/resources/thumbnails/'+ banner_category" style="width: 100%;">
      </div>
    </div>
  </div>
</section>

<section class="" *ngIf="isMobile">
  <div class="container-fluid remove-all-margin-padding">
    <div class="row remove-all-margin-padding">
      <div class="col-12 remove-all-margin-padding">
        <img [src]="url+'categoria/resources/thumbnails/'+ banner_mobile_category" style="width: 100%;">
      </div>
    </div>
  </div>
</section>


<section>
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="bebas rosetitle textwhite margintitlecover">Conoce nuestros diseños</h1>
        <span class="decor-cover"></span>
      </div>
    </div>
  </div>
</section>


<section class="mt-4 mb-3 mb-md-3">
  <div class="container none-mobile">
    <div class="row">

      <div class="col-12 col-md-3 col-lg-4 col-xl-6 mt-2 none-mobile">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
            <li class="poppinsbold smalltext textblack"><a class="text-nowrap" style="color: black;"
                                                        [routerLink]="['/']"><i
              class="czi-home mr-1"></i>Inicio</a>
            </li>
            <li class="poppinsbold smalltext textblack active"><a style="color: black;">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                   class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
              </svg>
              Nuestros diseños</a>
            </li>

          </ol>
        </nav>
      </div>


      <div class="col-12 col-md-9 col-lg-8 col-xl-6 text-right">
        <div class="btn-group">

          <div class="dropdown">

            <button class="poppins regulartext btn dropdown-toggle btn-sm" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Escoger Colección
            </button>

            <div class="dropdown-menu dropdown-menu-left"
                 aria-labelledby="dropdownMenuButton"
                 style="border-color:transparent; border-radius: 0 !important; background-color: #f4f4f4;">

              <div *ngFor="let categoria of categorias;let indice=index" class="border-top">

                <a class="dropdown-item poppinsbold smalltext" style="color: black"
                   [routerLink]="['/productos/'+categoria.nombre.toLowerCase().trim()]"
                   [attr.aria-controls]="'cat-'+indice">{{categoria.nombre}}
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                       class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                  </svg>
                </a>
              </div>

            </div>

          </div>

          <div class="dropdown mr-0 mr-md-5 mr-lg-0">
            <button class="poppins regulartext btn dropdown-toggle btn-sm" type="button" id="dropdownMenuButton2"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Ordenar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2"
                 style="border-color:transparent; border-radius: 0 !important; background-color: #f4f4f4;">
              <a class="dropdown-item poppinsbold smalltext" style="color: black; cursor: pointer;"
                 (click)="sort_filter('lower')">Menor a Mayor precio</a>
              <a class="dropdown-item poppinsbold smalltext" style="color: black; cursor: pointer;"
                 (click)="sort_filter('major')">Mayor a Menor precio</a>
              <a class="dropdown-item poppinsbold smalltext" style="color: black; cursor: pointer;"
                 (click)="sort_filter('desc')">Z - A</a>
              <a class="dropdown-item poppinsbold smalltext" style="color: black; cursor: pointer;"
                 (click)="sort_filter('asc')">A - Z</a>
            </div>
          </div>


        </div>
      </div>

    </div>
  </div>

  <div class="container-fluid none-desktop">
    <div class="row">

      <div class="col-5">
        <div class="dropdown">

          <button class="poppins regulartext btn dropdown-toggle btn-sm" type="button" id="dropdownMenuButtonmobile"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Colección
          </button>

          <div class="dropdown-menu dropdown-menu-left"
               aria-labelledby="dropdownMenuButtonmobile"
               style="border-color:transparent; border-radius: 0 !important; background-color: #f4f4f4;">

            <div *ngFor="let categoria of categorias;let indice=index" class="border-top">

              <a class="dropdown-item poppinsbold smalltext" style="color: black"
                 [routerLink]="['/productos/'+categoria.nombre.toLowerCase().trim()]"
                 [attr.aria-controls]="'cat-'+indice">{{categoria.nombre}}
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                        d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                </svg>
              </a>
            </div>

          </div>

        </div>
      </div>

      <div class="col-7">
        <div class="btn-group">


          <div class="dropdown mr-0 mr-md-5 mr-lg-0">
            <button class="poppins regulartext btn dropdown-toggle btn-sm" type="button" id="dropdownMenuButton2mobile"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Ordenar
            </button>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton2mobile"
                 style="border-color:transparent; border-radius: 0 !important; background-color: #f4f4f4;">
              <a class="dropdown-item poppinsbold" style="color: black; cursor: pointer; font-size: 72%;"
                 (click)="sort_filter('lower')">Menor a Mayor precio</a>
              <a class="dropdown-item poppinsbold" style="color: black; cursor: pointer; font-size: 72%;"
                 (click)="sort_filter('major')">Mayor a menor precio</a>
              <a class="dropdown-item poppinsbold" style="color: black; cursor: pointer; font-size: 72%;"
                 (click)="sort_filter('desc')">Z - A</a>
              <a class="dropdown-item poppinsbold" style="color: black; cursor: pointer; font-size: 72%;"
                 (click)="sort_filter('asc')">A - Z</a>
            </div>
          </div>


        </div>
      </div>

    </div>
  </div>
</section>

<section>
  <div class="container">
    <div class="row">
      <div class="col-12  text-center">
        <h1 class="poppins bigtitle textblack mb-2 mb-md-5 mt-5 mt-md-2 mt-lg-5">{{real_category_name}}</h1>
      </div>
    </div>
  </div>
</section>

<section class="mt-2 mb-5">
  <div class="container-fluid">
    <div class="row">
      <div class="col-6 col-md-4 col-lg-3 text-center mb-5 mt-3"
           *ngFor="let item of productos | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i=index">
        <img
          [src]="over[i] ? url+'productos_img_data/resources/thumbnails/'+item.hoverPoster:  url+'productos_img_data/resources/thumbnails/'+item.poster"
          [routerLink]="['/producto/',item.slug]"
          (mouseover)="over[i] = true" (mouseout)="over[i] = false" style="cursor: pointer;">
        <h4 class="poppinsbold regulartext textblack mt-2" style="margin-bottom: 1%"><a class="poppins regulartext textblack"
                                                                                   style="color: black;"
                                                                                   [routerLink]="['/producto/',item.slug]">{{item.titulo.substr(0, 69)}}</a>
        </h4>
        <p class="roboto bigtext textblack mt-2 mt-md-2 mt-lg-2 mt-xl-2 mb-2 mb-md-2 mb-lg-2 mb-xl-2"
           style="margin-bottom: 2%">$ {{item.precio_ahora}}</p>
        <a [routerLink]="['/producto/',item.slug]">
          <button type="button" class="btn btn-outline-dark btn-sm" style="">Ver producto
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 class="bi bi-arrow-right ml-2" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
            </svg>
          </button>
        </a>
      </div>
    </div>
  </div>
</section>


<section class="rosecolor texturespets">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h4 class="frasesize textwhite modernline text-center mb-3 mb-md-4 margin-steps " style="">Tu estilo nos inspira
        </h4>
      </div>

      <div class="col-3 text-center mb-5 none-mobile">
        <img class="mt-5" src="assets/img/icons/iconstep1.png" style="width: 30%;">
        <p class="bebas hugetext textwhite" style="line-height: 0.9;">Elegí tu diseño</p>
        <p class="poppins smalltext textwhite">Te ofrecemos diseños de calidad con variedad de telas y colores.</p>
      </div>

      <div class="col-3 text-center mb-5 none-mobile">
        <img class="mt-5" src="assets/img/icons/iconstep2.png" style="width: 30%;">
        <p class="bebas hugetext textwhite" style="line-height: 0.9;">Seleccioná tu talla</p>
        <p class="poppins smalltext textwhite">Elegí las medidas de tu prenda.</p>
      </div>

      <div class="col-3 text-center mb-5 none-mobile">
        <img class="mt-5" src="assets/img/icons/iconstep3.png" style="width: 30%;">
        <p class="bebas hugetext textwhite" style="line-height: 0.9;">Nosotros lo confeccionamos </p>
        <p class="poppins smalltext textwhite">Hacemos de tu diseño una pieza real con telas sostenibles y duraderas.</p>
      </div>

      <div class="col-3 text-center mb-5 none-mobile">
        <img class="mt-5" src="assets/img/icons/iconstep4.png" style="width: 30%;">
        <p class="bebas hugetext textwhite" style="line-height: 0.9;">Disfrutá tu diseño exclusivo</p>
        <p class="poppins smalltext textwhite" style="margin-bottom: 20%;">Una vez listo el diseño, estará pronto en tus
          manos. </p>
      </div>

      <div class="col-12 mb-5 none-desktop">
        <div id="carouselExampleControlsicons" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner">

            <div class="carousel-item active">
              <div class="container-fluid">
                <div class="row">

                  <div class="col-1"></div>

                  <div class="col-10 text-center">
                    <img class="mt-5" src="assets/img/icons/iconstep1.png" style="width: 30%;">
                    <p class="bebas bigtitle textwhite" style="line-height: normal;">Elegí tu diseño</p>
                    <p class="poppins regulartext textwhite">Te ofrecemos diseños de calidad con variedad de telas y
                      colores.</p>
                  </div>

                  <div class="col-1 "></div>
                </div>
              </div>
            </div>


            <div class="carousel-item">
              <div class="container-fluid">
                <div class="row">

                  <div class="col-1"></div>

                  <div class="col-10 text-center">
                    <img class="mt-5" src="assets/img/icons/iconstep2.png" style="width: 30%;">
                    <p class="bebas bigtitle textwhite" style="line-height: normal;">Seleccioná tu talla</p>
                    <p class="poppins regulartext textwhite">Elegí las medidas de tu prenda.</p>
                  </div>

                  <div class="col-1 "></div>
                </div>
              </div>
            </div>


            <div class="carousel-item">
              <div class="container-fluid">
                <div class="row">

                  <div class="col-1"></div>

                  <div class="col-10 text-center">
                    <img class="mt-5" src="assets/img/icons/iconstep3.png" style="width: 30%;">
                    <p class="bebas bigtitle textwhite" style="line-height: normal;">Nosotros te lo confeccionamos </p>
                    <p class="poppins regulartext textwhite">Hacemos de tu diseño una pieza real con telas sostenibles y
                      duraderas. </p>
                  </div>

                  <div class="col-1 "></div>
                </div>
              </div>
            </div>


            <div class="carousel-item">
              <div class="container-fluid">
                <div class="row">

                  <div class="col-1"></div>

                  <div class="col-10 text-center">
                    <img class="mt-5" src="assets/img/icons/iconstep4.png" style="width: 30%;">
                    <p class="bebas bigtitle textwhite" style="line-height: normal;">Disfrutá tu diseño exclusivo</p>
                    <p class="poppins regulartext textwhite" style="">Una vez listo el diseño, estará pronto en tus
                      manos. </p>
                  </div>

                  <div class="col-1 "></div>
                </div>
              </div>
            </div>

          </div>
          <a class="carousel-control-prev" href="#carouselExampleControlsicons" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true" style="filter: invert();"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleControlsicons" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true" style="filter: invert();"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>

    </div>
  </div>
</section>


<app-foot></app-foot>
