import { Component, OnInit } from '@angular/core';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PreguntaService} from '../../../services/pregunta.service';
import {Pregunta} from '../../../models/Pregunta';

declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-pregunta',
  templateUrl: './pregunta.component.html',
  styleUrls: ['./pregunta.component.css']
})
export class PreguntaComponent implements OnInit {

  public preguntas : Array<any> = [];
  public pregunta = new Pregunta('', '', '');
  public identity;
  public msm_error = '';
  public msm_success = '';

  constructor(
    private _userService: UserService,
    private _router : Router,
    private _route :ActivatedRoute,
    private _preguntaService : PreguntaService
  ) {
    this.identity = this._userService.getIdentity();
  }

  ngOnInit(): void {
    if(this.identity){
      if(this.identity.role == '12$MAhAAdPTi92gVknt8QyKIuEzcRYM6pa8.3RwTjFMfMwJvs2Jube'){
        this.initData();
      }
      else{
        this._router.navigate(['/']);
      }
    }else{
      this._router.navigate(['/']);
    }


  }

  initData(){
    this._preguntaService.listar().subscribe(
      response=>{
        this.preguntas = response.preguntas;
      },
      error =>{

      }
    );
  }

  eliminar(id){
    this._preguntaService.eliminar(id).subscribe(
      response=>{

        $('#modal-'+id).modal('hide');
        $('.modal-backdrop').removeClass('show');
        $('#dark-toast').removeClass('hide');
        $('#dark-toast').addClass('show');
        setTimeout(function() {
          $("#dark-toast").fadeOut(1500);
        },3000);
        this.initData();
      },
      error=>{

      }
    );
  }

  close_toast(){
    $('#dark-toast').removeClass('show');
    $('#dark-toast').addClass('hide');
  }

  onSubmit(preguntaForm){

    if(preguntaForm.valid && this.pregunta.pregunta && this.pregunta.respuesta){

      let data = {
        pregunta : preguntaForm.value.pregunta,
        respuesta: preguntaForm.value.respuesta
      };
      this._preguntaService.registro(data).subscribe(
        response =>{
          console.log(response);
          this.pregunta = new Pregunta('','','');
          this.msm_success = 'Pregunta Frecuente añadida';
          this.initData();
        },
        error=>{
          this.msm_error = 'Complete correctamente el formulario por favor.';
        }
      );

    }else{
      this.msm_error = 'Complete correctamente el formulario por favor.';
    }
  }


}
