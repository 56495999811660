import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from "./GLOBAL";

@Injectable({
  providedIn: 'root'
})
export class PortadaService {

   public url;

  constructor(
    private _http : HttpClient,
  ) {
    this.url = GLOBAL.url;
   }

  get_data():Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.get(this.url + 'portada',{headers:headers})
  }

  update(id,data):Observable<any>{
    const fd = new FormData();
    fd.append('tipo',data.tipo);
    fd.append('portada',data.portada);
    fd.append('portadaMobile',data.portadaMobile);
    fd.append('slider1',data.slider1);
    fd.append('slider1Mobile',data.slider1Mobile);
    fd.append('slider2',data.slider2);
    fd.append('slider2Mobile',data.slider2Mobile);
    fd.append('slider3',data.slider3);
    fd.append('slider3Mobile',data.slider3Mobile);
    fd.append('portadaMujer',data.portadaMujer);

    return this._http.put(this.url + 'portada/update',fd);
  }
}
