import { Component, OnInit, DoCheck } from '@angular/core';
import { Categoria } from "../../../../models/Categoria";
import { CategoriaService } from "../../../../services/categoria.service";
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

interface HtmlInputEvent extends Event{
  target : HTMLInputElement & EventTarget;
}

declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-create-categoria',
  templateUrl: './create-categoria.component.html',
  styleUrls: ['./create-categoria.component.css']
})
export class CreateCategoriaComponent implements OnInit,DoCheck {


  public file :File;
  public imgSelect : { banner: String | ArrayBuffer , mobile_banner: String | ArrayBuffer} = {banner: '', mobile_banner: ''};
  public categoria = new Categoria('','','','','', '','','');
  public msm_error;
  public msm_success;
  public identity;

  constructor(
    private _categoriaService : CategoriaService,
    private _userService: UserService,
    private _router : Router,
    private _route :ActivatedRoute
  ) {
    this.identity = this._userService.getIdentity();

   }


  ngOnInit(): void {

    if(this.identity){
      if(this.identity.role == '12$MAhAAdPTi92gVknt8QyKIuEzcRYM6pa8.3RwTjFMfMwJvs2Jube'){

      }
      else{
        this._router.navigate(['/']);
      }
    }else{
      this._router.navigate(['/']);
    }
  }

  ngDoCheck(): void {

  }

  imgSelected(event: HtmlInputEvent, fieldName){
    if(event.target.files  && event.target.files[0]){
        const reader = new FileReader();
        reader.onload = e => this.imgSelect[fieldName] = reader.result;
        reader.readAsDataURL(<File>event.target.files[0]);
        this.categoria[fieldName]  = <File>event.target.files[0];
    }
  }

  close_alert(){
    this.msm_error = '';
    this.msm_success = '';
  }

  onSubmit(categoriaForm){

    if(categoriaForm.valid){

      let data = {
        nombre : categoriaForm.value.nombre,
        banner : this.categoria['banner'] ,
        mobile_banner : this.categoria['mobile_banner'] ,
        description: categoriaForm.value.description,
        organicPercentage: categoriaForm.value.organicPercentage,
        recyclingPercentage: categoriaForm.value.recyclingPercentage

      }

      console.log(data);


      this._categoriaService.registro(data).subscribe(
        response =>{
          console.log(response);
          this.categoria = new Categoria('','','','','', '','','');
          this.imgSelect = {banner: '', mobile_banner: ''};
          this.msm_success = 'La categoria se registró con exito.'
          this._router.navigate(['admin/categorias']);
        },
        error=>{
          this.msm_error = 'Complete correctamente el formulario por favor.';
        }
      );

    }else{
      this.msm_error = 'Complete correctamente el formulario por favor.';
    }
  }

}
