import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from "./GLOBAL";

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  public url;

  constructor(
    private _http : HttpClient,
  ) {
    this.url = GLOBAL.url;
  }

  listar(filtro):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.get(this.url + 'article/'+filtro,{headers:headers})
  }

  find_by_slug(slug):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.get(this.url + 'article/slug/'+slug,{headers:headers})
  }

  findRandom():Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.get(this.url + 'article/random',{headers:headers})
  }

  listarLatest():Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.get(this.url + 'article/latest',{headers:headers})
  }


  list_one(id):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.get(this.url + 'article/one/'+id,{headers:headers})
  }

  eliminar(id):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.delete(this.url + 'article/'+id,{headers:headers})
  }

  update(data):Observable<any>{
    const fd = new FormData();
    fd.append('nombre',data.nombre);
    fd.append('body',data.body);
    fd.append('banner',data.banner);
    fd.append('mobile_banner',data.mobile_banner);
    return this._http.put(this.url + 'article/'+data._id,fd);
  }

  registro(data):Observable<any>{
    const fd = new FormData();
    fd.append('nombre',data.nombre);
    fd.append('body',data.body);
    fd.append('banner',data.banner);
    fd.append('mobile_banner',data.mobile_banner);
    return this._http.post(this.url + 'article/registro',fd);
  }
}
