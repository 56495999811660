<app-nav></app-nav>


<section class="rosecover">

</section>

<section>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 text-center">
        <h1 class="bebas rosetitle textwhite margin-text-cover">Contactate con nosotros</h1>
        <span class="decor-cover"></span>
      </div>
    </div>
  </div>
</section>

<section class="graycolor">
  <div class="container pt-grid-gutter">
    <div class="row">

      <div class="col-12 col-md-4 text-center mt-5 mb-5 mb-grid-gutter none-mobile">
        <div class="card" style="border-radius: 0 !important;">
          <div class="card-body text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor"
                 class="bi bi-telephone-fill mt-5 mb-3 textblack" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
            </svg>
            <h3 class="poppins hugetext textblack mb-5">Teléfono</h3>
            <p class="roboto bigtext textblack mb-4">
              <strong>Whatsapp:</strong> +506 7260 1197
            </p>
            <p class="roboto bigtext mt-4" style="color: transparent;">
              <strong>Telf:</strong>
            </p>

          </div>
        </div>
      </div>


      <div class="col-12 col-md-4 text-center mt-5 mb-5 mb-grid-gutter none-mobile">
        <div class="card" style="border-radius: 0 !important;">
          <div class="card-body text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor"
                 class="bi bi-envelope-fill mt-5 mb-3 textblack" viewBox="0 0 16 16">
              <path
                d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
            </svg>
            <h3 class="poppins hugetext textblack mb-5">E-mail</h3>
            <p class="roboto bigtext textblack mt-4">
              info@amagnr.com
            </p>
            <p class="roboto bigtext mt-4" style="color: transparent;">
              <strong>Telf:</strong>
            </p>

          </div>
        </div>
      </div>


      <div class="col-12 col-md-4 text-center mt-5 mb-5 mb-grid-gutter none-mobile">
        <div class="card" style="border-radius: 0 !important;">
          <div class="card-body text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor"
                 class="bi bi-person-circle mt-5 mb-3 textblack" viewBox="0 0 16 16">
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
              <path fill-rule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
            </svg>
            <h3 class="poppins hugetext textblack mb-5">Redes sociales</h3>
            <p class="roboto bigtext textblack mt-4">
              <a target="_blank" href="https://www.instagram.com/amagbynr/" style="color: black;">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                     class="bi bi-instagram mr-1" viewBox="0 0 16 16">
                  <path
                    d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                </svg>
                amagbynr</a>
            </p>
            <p class="roboto bigtext textblack mb-4">
              <a target="_blank" href="https://www.facebook.com/amagbynr/" style="color: black;">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                     class="bi bi-facebook mr-1" viewBox="0 0 16 16">
                  <path
                    d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                </svg>
                amagbynr</a>
            </p>

          </div>
        </div>
      </div>

<!--      Here you find the contact box-->

      <div class="col-12 col-md-4 text-center mt-2 mb-grid-gutter none-desktop">
        <div class="card" style="border-radius: 0 !important; border: none; background-color: transparent;">
          <div class="card-body text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                 class="bi bi-person-circle mt-3 mb-3 textblack" viewBox="0 0 16 16">
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
              <path fill-rule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
            </svg>
            <h3 class="poppins hugetext textblack mb-3">Redes sociales</h3>
            <p class="roboto bigtext textblack mt-4">
              <a target="_blank" href="https://www.instagram.com/amagbynr/" style="color: black;">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                     class="bi bi-instagram mr-1" viewBox="0 0 16 16">
                  <path
                    d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                </svg>
                amagbynr</a>
            </p>
            <p class="roboto bigtext textblack mb-4">
              <a target="_blank" href="https://www.facebook.com/amagbynr/" style="color: black;">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                     class="bi bi-facebook mr-1" viewBox="0 0 16 16">
                  <path
                    d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                </svg>
                amagbynr</a>
            </p>

          </div>
        </div>
      </div>


      <div class="col-12 col-md-4 text-center mb-grid-gutter none-desktop">
        <div class="card" style="border: none; border-radius: 0 !important; background-color: transparent;">
          <div class="card-body text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor"
                 class="bi bi-telephone-fill mb-3 textblack" viewBox="0 0 16 16">
              <path fill-rule="evenodd"
                    d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
            </svg>
            <h3 class="poppins hugetext textblack mb-3">Teléfono</h3>
            <p class="roboto bigtext textblack">
              <strong>Whatsapp:</strong> +506 7260 1197
            </p>

          </div>
        </div>
      </div>


      <div class="col-12 col-md-4 text-center mb-5 mb-grid-gutter none-desktop">
        <div class="card" style="border-radius: 0 !important; border: none; background-color: transparent;">
          <div class="card-body text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="currentColor"
                 class="bi bi-envelope-fill mb-3 textblack" viewBox="0 0 16 16">
              <path
                d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
            </svg>
            <h3 class="poppins hugetext textblack">E-mail</h3>
            <p class="roboto bigtext textblack mt-4">
              info@amagnr.com
            </p>

          </div>
        </div>
      </div>





    </div>
  </div>
</section>

<section class="graycolor">
  <div class="container">
    <div class="row">
      <div class="col-1 col-md-2"></div>

      <div class="col-10 col-md-8">
        <h2 class="poppins bigtitle text-center textblack mt-0 mt-md-5 mb-4">Contactanos</h2>
        <form class="mb-3" #contactoForm="ngForm" (ngSubmit)="onSubmit(contactoForm)">
          <div class="row">
            <div class="col-sm-12" *ngIf="msm_success">
              <div class="alert alert-success alert-dismissible fade show" role="alert">
                Se envió su mensaje satisfactoriamente, pronto nos pondremos en contacto.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div class="col-sm-12" *ngIf="msm_error">
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                Ocurrió un error al enviar la solicitud, vuelva a intentar.
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group center-text-component">
                <label class="poppins smalltext textblack" for="cf-name">Nombre y apellido:&nbsp;<span
                  class="text-danger">*</span></label>
                <input class="form-control" type="text" id="cf-name" placeholder="" required name="nombres"
                       [(ngModel)]="contacto.nombres">

              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group center-text-component">
                <label class="poppins smalltext textblack " for="cf-email">Correo:&nbsp;<span
                  class="text-danger">*</span></label>
                <input class="form-control" type="email" id="cf-email" placeholder="email@email.com" required
                       name="correo" [(ngModel)]="contacto.correo">

              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group center-text-component">
                <label class="poppins smalltext textblack" for="cf-phone">Teléfono:&nbsp;<span class="text-danger">*</span></label>
                <input class="form-control" type="number" id="cf-phone" placeholder="0000 0000" required
                       name="telefono" [(ngModel)]="contacto.telefono">

              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group center-text-component">
                <label class="poppins smalltext textblack" for="cf-subject">Tema: <span class="text-danger">*</span></label>
                <input class="form-control" type="text" id="cf-subject" placeholder="Tema de tu solicitud" name="tema"
                       [(ngModel)]="contacto.tema">
              </div>
            </div>
          </div>
          <div class="form-group center-text-component">
            <label class="poppins smalltext textblack" for="cf-message">Dejanos un comentario:&nbsp;<span
              class="text-danger">*</span></label>
            <textarea class="form-control" id="cf-message" rows="6" placeholder="Escribe los detalles de tu solicitud"
                      required name="mensaje" [(ngModel)]="contacto.mensaje"></textarea>

          </div>
          <button class="btn btn-dark" type="submit" [disabled]="btn_send">Enviar mensaje</button>
        </form>
      </div>

      <div class="col-1 col-md-2"></div>

      <div class="col-1 col-md-2 col-lg-3"></div>

      <div class="col-10 col-md-8 col-lg-6 text-center">
        <h3 class="modernline frasesize textblack mt-5 mb-5">Tu estilo nos inspira</h3>
        <!--<p class="poppins regulartext textblack mt-5 mt-md-3 mt-lg-4 mb-5">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
          dolore magna aliquam erat volutpat. Ut wisi enim ad.</p>-->
      </div>

      <div class="col-1 col-md-2 col-lg-3"></div>

    </div>
  </div>
</section>


<app-foot></app-foot>
