import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from "./GLOBAL";
import { Carrito } from "../models/Carrito";

@Injectable({
  providedIn: 'root'
})
export class CarritoService {

  public url;

  constructor(
    private _http : HttpClient,
  ) {
    this.url = GLOBAL.url;
   }

  registro(new_producto):Observable<any>{
    let new_carrito = JSON.parse(localStorage.getItem('carrito'));
    if (!new_carrito) {
      new_carrito = [];
    }
    new_carrito.push(new_producto);
    var new_carrito_string = JSON.stringify(new_carrito);
    localStorage.setItem('carrito', new_carrito_string);
    return  of(new_carrito);
  }



  preview_carrito(/*id*/):Observable<any>{
    let carrito = JSON.parse(localStorage.getItem('carrito'));
    if(!carrito){
      carrito = [];
    }
    return of({carrito: carrito});
  }

  remove_carrito(index):Observable<any>{
    let new_carrito = JSON.parse(localStorage.getItem('carrito'));
    if (!new_carrito) {
      new_carrito = [];
    }
    const deleted = new_carrito[index];
    new_carrito.splice(index, 1);
    var new_carrito_string = JSON.stringify(new_carrito);
    localStorage.setItem('carrito', new_carrito_string);
    return of({product: deleted});
  }
}
