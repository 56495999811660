import { Component, OnInit } from '@angular/core';
import { ArticleService } from 'src/app/services/article.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

declare var jQuery:any;
declare var $:any;

@Component({
  selector: 'app-index-article',
  templateUrl: './index-article.component.html',
  styleUrls: ['./index-article.component.css']
})
export class IndexArticleComponent implements OnInit {

  public articles : Array<any> = [];
  public page;
  public pageSize = 15;
  public count_art;
  public filtro;
  public identity;

  constructor(
    private _userService: UserService,
    private _router : Router,
    private _route :ActivatedRoute,
    private _articleService : ArticleService
  ) {
    this.identity = this._userService.getIdentity();
  }

  ngOnInit(): void {
    if(this.identity){
      if(this.identity.role == '12$MAhAAdPTi92gVknt8QyKIuEzcRYM6pa8.3RwTjFMfMwJvs2Jube'){
        this.resetSearch();
      }
      else{
        this._router.navigate(['/']);
      }
    }else{
      this._router.navigate(['/']);
    }


  }

  resetSearch(){
    this.filtro = ''
    this._articleService.listar('').subscribe(
      response=>{
        this.articles = response.articles;
        this.count_art = this.articles.length;
        this.page = 1;


      },
      error =>{

      }
    );
  }

  search(searchForm){
    console.log(this.filtro);
    this._articleService.listar(this.filtro).subscribe(
      response=>{
        this.articles = response.articles;

      },
      error =>{

      }
    );
  }

  eliminar(id){
    this._articleService.eliminar(id).subscribe(
      response=>{

        $('#modal-'+id).modal('hide');
        $('.modal-backdrop').removeClass('show');
        $('#dark-toast').removeClass('hide');
        $('#dark-toast').addClass('show');
        setTimeout(function() {
          $("#dark-toast").fadeOut(1500);
        },3000);
        this.resetSearch();
      },
      error=>{

      }
    );
  }

  close_toast(){
    $('#dark-toast').removeClass('show');
    $('#dark-toast').addClass('hide');
  }


}
